const CURRECNY_PRICE = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
const CURRECNY_PRICE_FOUR_DECIMAL = { minimumFractionDigits: 4, maximumFractionDigits: 4 }

const TOKENSUPPLY = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

const TOKENSUPPLY_FOUR_DECIMAL = { minimumFractionDigits: 4, maximumFractionDigits: 4 }


export const convertToCurrency = (num: number): string => {
    return num.toLocaleString(undefined, CURRECNY_PRICE);
}

export const convertToCurrencyFourDecimal = (num: number): string => {
    return num.toLocaleString(undefined, CURRECNY_PRICE_FOUR_DECIMAL);
}

export const convertToToken = (num: number): string => {
    return num.toLocaleString(undefined, TOKENSUPPLY);
}

export const convertToTokenFourDecimal = (num: number): string => {
    return num.toLocaleString(undefined, TOKENSUPPLY_FOUR_DECIMAL);
}

export const convertToPercantage = (num: number): string => {
    return num.toLocaleString(undefined, TOKENSUPPLY);
}