import React, { useCallback, useEffect, useState, useContext, useRef } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { gsap } from 'gsap'

// import Web3Status from '../Web3Status'
import { useWalletModalToggle } from '../../state/application/hooks'
import styled, { css, ThemeContext } from 'styled-components'
import IFOCounter from '../IFOCounter'
import { Collapse } from 'react-collapse'
import bnbICON from '../../assets/images/bnbICON.png'
import defaultImage from '../../assets/images/default.png'
// import { useCurrency } from '../../hooks/Tokens'
// import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
// import { parseUnits } from '@ethersproject/units'
// import { Currency, CurrencyAmount, JSBI, Token, TokenAmount } from '@bscswap/sdk'
// import { MaxUint256 } from '@ethersproject/constants'
import { useIFOContract, useOrderContract, usePDOContract } from '../../hooks/useContract'
//import TextBox from '../TextBox'
import TextBoxNumaric from '../TextBoxNumaric'
import { calculateGasMargin, getEtherscanLink } from '../../utils'
import TransactionConfirmationModal from '../TransactionConfirmationModal'
// import { BSWAP_LP_ADDRESS } from '../../constants'
import { useAlert } from 'react-alert'
// import { useTransactionAdder } from '../../state/transactions/hooks'
import axios from 'axios'
import { API_BASEURL, ACTIVE_NETWORK } from '../../constants'
import { ORDER_BOOK_ADDRESSES, ORDER_BOOK_INTERFACE, PDO_MAIN_INTERFACE } from '../../constants/pdo'
import { ethers } from 'ethers'
import Media from '../../theme/media-breackpoint'
import FutureBar from '../FutureBar'
import Slider from 'react-slick'
import Tooltip from '../ToolTip'
import ConfirmTxPopup from '../ConfirmTxPopup'
import web3Js from 'web3'
import { utils } from 'ethers'
import BigNumber from 'bignumber.js'
import { convertToCurrency, convertToPercantage, convertToToken } from '../../utils/numberConveter'
import { KycConfimation } from '../../components/KycPopUp'


const api = axios.create({
    baseURL: API_BASEURL
})

interface IFOProps {
    data
    balance?: string
    refresh?
    price?: number
    viewType?: number
}

const PRICE_DENOMINATOR = new BigNumber(10).exponentiatedBy(9)
const PERCENT_DENOMINATOR = 100
const ETH_DENOMINATOR = new BigNumber(10).exponentiatedBy(18)

export default function IFOCard({ data, balance, refresh, price, viewType }: IFOProps) {
    // const ROUTER_ADDRESS = '0xd954551853F55deb4Ae31407c423e67B1621424A'
    // const BSWAPADDRESS = "0xb41A8426e96d0427dd45a2B6EB21996101808616"

    let button: JSX.Element
    let marginMiddle: JSX.Element
    let marginTop: JSX.Element
    let marginBottom: JSX.Element

    let title // = "Current PDO begins in"
    let titleRed
    let displayCounter: number = 0
    let counterTime: number = 0
    let status = ''
    let statusClass = ''

    const { account, chainId } = useActiveWeb3React()
    const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected
    const theme = useContext(ThemeContext)
    const SliderDivRef = useRef(null)

    const [isOpened, setIsOpened] = useState(false)
    const [details, setDetails] = useState('Details')
    const [detailsClass, setDetailsClass] = useState('fas fa-chevron-down')
    const [activeDiv, setActiveDiv] = useState(false)
    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [startMark, setStartMark] = useState<boolean>(false)

    const [amount, setAmount] = useState<number>(0)
    const [tokenGet, setTokenGet] = useState<number>(0)
    const [txHash, setTxHash] = useState<string>('')

    const [bnbAmount, setBNBAmount] = useState<string>('')

    const [orders, setOrders] = useState([])
    const [displayOrders, setDisplayOrders] = useState([])
    const [isFutureBarOpen, setIsFutureBarOpen] = useState(false)

    const [txPopUp, setTxPopUp] = useState(false)
    const [isTxFailed, setIsTxFailed] = useState(false)
    const [popUpMsg, setPopUpMsg] = useState<string | JSX.Element>(null)

    const [currentOrder, setCurrentOrder] = useState(0)
    const [userUsdLimit, setUserUsdLimit] = useState(0)
    const [userTokenLimit, setUserTokenLimit] = useState(0)
    const [refundPending, setRefundPending] = useState<boolean>(false)
    const [isKycPopup, setKycPopup] = useState<boolean>(false)
    const [tokenImage, setTokenImage] = useState('')

    // const [estimatedTokens, setEstimatedTokens] = useState<Number>(0)
    const pdoContract = usePDOContract(data.pdoAddress)
    const alert = useAlert()

    const orderStatus = {
        PENDING: 'PENDING',
        EXCUTED: 'EXCUTED',
        CANCELLED: 'CANCELLED'
    }

    const enumOrderStatus = [orderStatus.PENDING, orderStatus.EXCUTED, orderStatus.CANCELLED]


    useEffect(() => {
        const call = async () => {
            const contractData = await axios.get(`https://api.coingecko.com/api/v3/coins/binance-smart-chain/contract/${data.poolInfo.offeringToken}`)
            if (contractData.status === 200) {
                const Image = contractData.data.image.large;
                setTokenImage(Image);
            }
        }
        call();
    }, [])


    useEffect(() => {
        callOrder()
    }, [account])

    useEffect(() => {
        gsap.to(SliderDivRef.current, {
            ease: 'none',
            opacity: 0.1,
            onComplete: () => {
                gsap.to(SliderDivRef.current, {
                    ease: 'none',
                    opacity: 1
                })
            }
        })
    }, [currentOrder])

    useEffect(() => {
        setRefundPending(false)
        callDisplayOrder()
    }, [orders])

    const slideSwaped = current => {
        gsap.to(SliderDivRef.current, {
            ease: 'none',
            opacity: 0.1,
            onComplete: () => {
                gsap.to(SliderDivRef.current, {
                    ease: 'none',
                    opacity: 1
                })
            }
        })
        setCurrentOrder(Number(current))
    }

    const handleKyc = async (bool) => {
        setKycPopup(false);
    }

    const claimUnsold = async () => {
        const a = await pdoContract.pdoInfo();
        console.log(a);
        const totalSupply = a.totalSupply._hex;

        // removeOfferingTokens
        if (pdoContract !== null) {
            let onError = false
            try {
                setShowConfirm(true)
                const estimatedGas = await pdoContract.estimateGas.removeOfferingTokens(totalSupply)
                const txResponse = await pdoContract
                    .removeOfferingTokens(totalSupply, {
                        gasLimit: calculateGasMargin(estimatedGas),
                        value: 0
                    })
                    .catch(() => {
                        onError = true
                        setShowConfirm(false)
                        setAmount(0)
                        setBNBAmount('')
                    })

                if (onError === false) {
                    setTxHash(txResponse.hash)
                    const txReceipt = await txResponse.wait()
                    setIsTxFailed(txReceipt.status === 1 ? false : true)
                    if (txReceipt.status === 1) {
                        setPopUpMsg('Your Token Withdrawd is successfully')
                        setTxHash('')
                        closePopupInSuccess()
                        setTxPopUp(true)

                    } else {
                        setShowConfirm(false)
                        setPopUpMsg('You Withdraw is failed!')
                        setTxHash('')
                        setTxPopUp(true)
                    }
                }
            } catch (error) {
                //console.log(error.message)
                onError = true
                setShowConfirm(false)
                console.log(error)
                alert.show(error.data.message)
            }
        }
    }


    function callDisplayOrder() {
        const a = []
        let tempUsd = 0
        let tempToken = 0
        for (let index = 0; index < orders.length; index++) {
            const element = orders[index]
            if (!element.isFuture || (element.isFuture && element.futureStatus === 'EXCUTED')) {
                a.push(element)
            }
            if (element.isFuture && element.futureStatus === 'PENDING')
                setRefundPending(true)


            tempUsd += element.usdAmount
            tempToken += element.tokenAmount
        }
        setUserTokenLimit(tempToken)
        setUserUsdLimit(tempUsd)
        a.sort((x, y) => Number(x.isFilled) - Number(y.isFilled));
        setDisplayOrders(a)
    }

    const callOrder = () => {
        api
            .post(`/getOrder`, {
                filter: { user: account, pdoAddress: data.pdoAddress }
            })
            .then(response => {
                setOrders(response.data.data)
            })
    }

    const orderRefresh = () => {
        window["transactionPending"] = false;
        refresh()
        callOrder()

    }

    const padZero = n => {
        return ('0' + n).slice(-2)
    }

    function formatTime(time) {
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
        // var d = new Date();
        const date = new Date(time * 1000)
        const hour = date.getUTCHours()
        var ampm = hour >= 12 ? 'PM' : 'AM'
        var h = hour <= 12 ? hour : hour - 12
        return (
            months[date.getMonth()] +
            '. ' +
            padZero(date.getUTCDate()) +
            ', ' +
            padZero(h) +
            ':' +
            padZero(date.getUTCMinutes()) +
            ' ' +
            ampm +
            ' UTC'
        )
    }


    const toWei = (val: string): string => {
        var wei = ethers.utils.parseEther(val)
        return wei.toString()
    }

    const closePopupInSuccess = () => {
        setStartMark(true)
        setShowConfirm(false)
        setStartMark(false)
    }

    const closePopUp = flag => {
        setTxPopUp(flag)
        orderRefresh()
    }

    const claimOrder = async orderId => {
        if (pdoContract !== null) {
            let onError = false
            try {
                setShowConfirm(true)
                const estimatedGas = await pdoContract.estimateGas.redeemTokens(orderId)
                const txResponse = await pdoContract
                    .redeemTokens(orderId, {
                        gasLimit: calculateGasMargin(estimatedGas)
                    })
                    .catch(() => {
                        onError = true
                        setShowConfirm(false)
                    })
                setTxHash(txResponse.hash)
                const txReceipt = await txResponse.wait()
                setIsTxFailed(txReceipt.status === 1 ? false : true)
                if (txReceipt.status === 1) {
                    setPopUpMsg('Your Order Claimed Succesfully')
                    setTxHash('')
                    closePopupInSuccess()
                    setTxPopUp(true)
                } else {
                    setShowConfirm(false)
                    setPopUpMsg('Your claiming order failed due to some reason')
                    setTxHash('')
                    setTxPopUp(true)
                }
            } catch (error) {
                console.log(error)
                onError = true
                setShowConfirm(false)
                alert.show(error.data.message)
            }
        }
    }

    const depositBNB = async (sentAmount: number, isFuture: boolean) => {
        if (data.investInfo.limitType === 'USD_LIMIT') {
            if (userUsdLimit + amount < data.investInfo.minInvest) {
                alert.show(`You have to invest minimum ${data.investInfo.minInvest} Usd`)
                return
            }

            if (userUsdLimit + amount > data.investInfo.maxInvest) {
                alert.show(`You have maximum Limit of ${data.investInfo.maxInvest - userUsdLimit} Usd`)
                return
            }
        }

        if (data.investInfo.limitType === 'TOKEN_LIMIT') {
            if (userTokenLimit + tokenGet < data.investInfo.minInvest) {
                alert.show(`You have to purchase minimum ${data.investInfo.minInvest} ${data.tokenInfo.symbol.toUpperCase()}`)
                return
            }

            if (userTokenLimit + tokenGet > data.investInfo.maxInvest) {
                alert.show(`You can purchase maximum ${data.investInfo.maxInvest - userTokenLimit} ${data.tokenInfo.symbol.toUpperCase()}`)
                return
            }
        }





        const amountB = (sentAmount + (sentAmount * (data.liquidation.companyFee + 0.0001)) / 100).toFixed(18)

        if (parseFloat(amountB.toString()) > parseFloat(balance)) {
            alert.show('You dont have enough balance')
            return
        }

        if (pdoContract !== null) {
            let onError = false
            try {
                setShowConfirm(true)
                const amt = toWei(amountB.toString())
                const investAmount = toWei(sentAmount.toFixed(18).toString())

                const estimatedGas = await pdoContract.estimateGas.invest(investAmount, isFuture, { value: amt })
                const txResponse = await pdoContract
                    .invest(investAmount, isFuture, {
                        gasLimit: calculateGasMargin(estimatedGas),
                        value: amt
                    })
                    .catch(() => {
                        onError = true
                        setShowConfirm(false)
                        setAmount(0)
                        setBNBAmount('')
                    })

                if (onError === false) {
                    setTxHash(txResponse.hash)
                    window["transactionPending"] = true;
                    const txReceipt = await txResponse.wait()
                    setIsTxFailed(txReceipt.status === 1 ? false : true)
                    let isRefund = false;
                    if (txReceipt.status === 1) {
                        let eventData
                        const events = txReceipt.events
                        for (let i = 0; i < events.length; i++) {
                            const ev = events[i]
                            if (ev.event === 'EthRefunded') {
                                eventData = PDO_MAIN_INTERFACE.parseLog(ev).args;
                                isRefund = true;
                            }
                        }

                        if (isRefund) {
                            let refund = web3Js.utils.fromWei(eventData.amount._hex);
                            let message = (
                                <span style={{ fontSize: '20px' }}>
                                    Your
                                    <span style={{ color: 'white' }}> {sentAmount} &nbsp;</span>
                                    BNB has been successfully received but exceeds the maximum amount available to invest. The smart
                                    contract is refunding
                                    <span style={{ color: 'white' }}>&nbsp;{refund} &nbsp;</span>
                                    BNB back to your wallet.
                                    <p style={{ color: 'white' }}>
                                        <a href={getEtherscanLink(chainId, txHash, 'transaction')} target="_blank">
                                            See Proof
                                        </a>
                                    </p>
                                </span>
                            )
                            setPopUpMsg(message)
                        } else {
                            setPopUpMsg('Your investment is successfully received')
                        }
                        setAmount(0)
                        setBNBAmount('')
                        setTxHash('')
                        closePopupInSuccess()
                        setTxPopUp(true)
                        setTokenGet(0)
                    } else {
                        setShowConfirm(false)
                        setPopUpMsg('You investment is failed!')
                        setAmount(0)
                        setBNBAmount('')
                        setTxHash('')
                        setTxPopUp(true)
                        setTokenGet(0)
                    }
                }
            } catch (error) {
                //console.log(error.message)
                onError = true
                setShowConfirm(false)
                alert.show(error.data.message)
            }
        }
    }

    const handleInvestment = () => {
        if (data.investInfo.isKyc) {
            if (localStorage.getItem(data.pdoAddress) !== '1') {
                setKycPopup(true);
                return;
            }
        }

        if (bnbAmount === '' || bnbAmount === '0') {
            alert.show('Please enter valid amount')
            return
        }
        depositBNB(Number(bnbAmount), false)
    }

    const handleDismissConfirmation = useCallback(() => {
        setShowConfirm(false)
    }, [])

    const handlAmountChange = _amount => {

        const amountB = parseFloat(_amount) + (parseFloat(_amount) * (data.liquidation.companyFee + 0.0001)) / 100

        if (parseFloat(amountB.toString()) > parseFloat(balance)) {
            alert.show('You dont have enough balance')
            return
        }
        setBNBAmount(_amount)
        let usdAmount = Number(_amount) * price
        setAmount(Number(usdAmount.toFixed(2)))
        setTokenGet(usdAmount / data.offeringTokenPrice)
    }

    const claimButton = () => {
        if (displayOrders.length > 0) {

            if (data.pdoType === "AUCTION" && (data.softCap) > Number(data.liquidation.soldOutUsd.$numberDecimal)) {

                if (displayOrders[currentOrder].isFilled) {
                    return (
                        <>
                            <NormalButton viewType={viewType} className='changeBtn'> Already refunded</NormalButton>
                            <BTMRow>
                                <IDOMaxBtn onClick={maxToken}></IDOMaxBtn>
                                {(data.isStaking) && <IdoFuture onClick={() => setIsFutureBarOpen(true)}> {refundPending ? <span style={{ color: '#FFA500' }}>Order pending </span> : 'Secure future'}</IdoFuture>}
                            </BTMRow>
                        </>
                    )
                } else {
                    return (
                        <>
                            <NormalButton viewType={viewType} className='changeBtn'> Token Already Claimed</NormalButton>
                            <BTMRow>
                                <IDOMaxBtn onClick={maxToken}></IDOMaxBtn>
                                {(data.isStaking) && <IdoFuture onClick={() => setIsFutureBarOpen(true)}> {refundPending ? <span style={{ color: '#FFA500' }}>Order pending </span> : 'Secure future'}</IdoFuture>}
                            </BTMRow>
                        </>
                    )

                }

            } else if (displayOrders[currentOrder].isFilled) {
                return (
                    <>
                        <NormalButton viewType={viewType} className='changeBtn'> Token Already Claimed</NormalButton>
                        <BTMRow>
                            <IDOMaxBtn onClick={maxToken}></IDOMaxBtn>
                            {(data.isStaking) && <IdoFuture onClick={() => setIsFutureBarOpen(true)}> {refundPending ? <span style={{ color: '#FFA500' }}>Order pending </span> : 'Secure future'}</IdoFuture>}
                        </BTMRow>
                    </>
                )
            } else if (displayOrders[currentOrder].endDate * 1000 > new Date().getTime()) {
                return (
                    <>
                        <IFOTitle01>You can claim in</IFOTitle01>

                        <IFOCounter01>
                            <IFOCounter counterTime={displayOrders[currentOrder].endDate} viewType={viewType}></IFOCounter>
                        </IFOCounter01>

                        <BTMRow>
                            <IDOMaxBtn onClick={maxToken}></IDOMaxBtn>
                            {(data.isStaking) && <IdoFuture onClick={() => setIsFutureBarOpen(true)}>{refundPending ? <span style={{ color: '#FFA500' }}>Order pending </span> : 'Secure future'}</IdoFuture>}
                        </BTMRow>
                    </>
                )
            } else if (new Date().getTime() > displayOrders[currentOrder].endDate * 1000) {
                return (
                    <>
                        <GreenButton viewType={viewType} onClick={() => claimOrder(displayOrders[currentOrder].orderAddress)} className='changeBtn'>
                            {' '}
                            Claim Your Token
                        </GreenButton>
                        <BTMRow>
                            <IDOMaxBtn onClick={maxToken}></IDOMaxBtn>
                            {(data.isStaking) && <IdoFuture onClick={() => setIsFutureBarOpen(true)}>{refundPending ? <span style={{ color: '#FFA500' }}>Order Pending </span> : 'Secure future'}</IdoFuture>}
                        </BTMRow>
                    </>
                )
            }
        } else {
            return (
                <BTMRow>
                    <IDOMaxBtn onClick={maxToken}></IDOMaxBtn>
                    {(data.isStaking) && <IdoFuture onClick={() => setIsFutureBarOpen(true)}>{refundPending ? <span style={{ color: '#FFA500' }}> Order Pending </span> : 'Secure future'}</IdoFuture>}
                </BTMRow>
            )
        }
    }

    // let input:Boolean = false
    const checkRaisedAmount = () => {
        displayCounter = 3
        if (data.percentAgeRaised >= 100) {
            titleRed = '100% Sold Out'
        } else {
            titleRed = 'PDO Ended'
        }

    }

    const calculateMaxToken = () => {
        let available = data.poolInfo.supplyToDistribute
        if (available === 3e-18) return { bnb: 0, token: 0, amount: 0 };

        if (data.investInfo.limitType === 'TOKEN_LIMIT') {
            let tmpLimit = data.investInfo.maxInvest - userTokenLimit;
            if (available > tmpLimit) {
                available = tmpLimit
            }
        }
        if(Math.sign(available) === -1){
            return { bnb: 0, token: 0, amount: 0 }
        }

        let t = data.offeringTokenPrice * available
        if (data.investInfo.limitType === 'USD_LIMIT') {
            t = data.investInfo.maxInvest - userUsdLimit;
        }

        if(Math.sign(t) === -1){
            return { bnb: 0, token: 0, amount: 0 }
        }

        let max = t / price
        if (max < 0.0001) {
            max = 0.0001;
        }
        const amountB = max + (max * (data.liquidation.companyFee + 0.0001)) / 100


        if (parseFloat(amountB.toString()) > parseFloat(balance)) {
            let tmpInvestAmount = parseFloat(balance) - (((max * (data.liquidation.companyFee + 0.0001)) / 100) + 0.0001)

            if (Math.sign(tmpInvestAmount) === -1) {
                return { bnb: 0, token: 0, amount: 0 };
            }
            let usdAmount = Number(tmpInvestAmount) * price
            return { bnb: tmpInvestAmount.toString(), token: usdAmount / data.offeringTokenPrice, amount: Number(usdAmount.toFixed(2)) };
        }

        return { bnb: max.toString(), token: t / data.offeringTokenPrice , amount: t };
    }

    const maxToken = () => {
        const retrunValue = calculateMaxToken();
        setBNBAmount(retrunValue.bnb.toString())
        setAmount(retrunValue.amount)
        setTokenGet(retrunValue.token)
    }

    const displayInput = () => {
        displayCounter = 1
        title = 'Current PDO End in'
        counterTime = data.endTime
        // input = true

        button = (
            <>
                <InputBar01 viewType={viewType}>
                    <SpanIDOBar viewType={viewType}>
                        {/* <DDLinkbtn><i className="fas fa-caret-down"></i></DDLinkbtn> */}
                        <BinanceImage src={bnbICON} />

                        <DDLinkbtn><i className="fas fa-caret-down"></i></DDLinkbtn>
                    </SpanIDOBar>
                    <TextBoxNumaric
                        value={bnbAmount}
                        onUserInput={handlAmountChange}
                        placeholder="Enter BNB"
                        viewType={viewType}
                    ></TextBoxNumaric>
                    <InvestButton viewType={viewType} onClick={handleInvestment} className='changeBtn'>
                        Invest
                    </InvestButton>
                </InputBar01>
                <BTMRow viewType={viewType}>
                    <IDOMaxBtn onClick={maxToken}>
                        {' '}
                        Max
                        <span style={{ marginLeft: '20px' }}>
                            [$ {amount}]
                            <p>
                                {' '}
                                {convertToToken(tokenGet)} {data.tokenInfo.symbol.toUpperCase()}
                            </p>
                        </span>
                    </IDOMaxBtn>

                    {data.isStaking && (
                        <IdoFuture onClick={() => setIsFutureBarOpen(true)}>
                            {/* <label className="switch">
                            <input type="checkbox" checked={isFutue} onClick={() => setIsFuture(!isFutue)} />
                            <span className="slider round"></span>
                        </label> */}
                            {refundPending ? <span style={{ color: '#FFA500' }}>Order Pending </span> : 'Secure future'}
                        </IdoFuture>
                    )}
                </BTMRow>
            </>
        )
        // if(viewType === 1){
        //     marginMiddle=<div style={{height:'7px'}}>&nbsp;</div>
        // }
    }

    const ifoNotStartButton = () => {
        title = (
            <>
                <CmgSoon>Coming Soon</CmgSoon>
                <div>Current PDO start in</div>
            </>
        )
        counterTime = data.startTime //1613692800//
        displayCounter = 2
        button = null
    }

    // const ifoOverButton = () => {
    //     title = <>
    //         <div onMouseEnter={() => {
    //             displayCounter = 1
    //         }} onMouseLeave={() => {
    //             displayCounter = 0
    //         }}>Comming Soon!</div>
    //     </>
    //     displayCounter = 2
    //     button = (<DisableButton viewType={viewType}>PDO is Over</DisableButton>)
    // }


    const displayButton = () => {
        if (data.percentAgeRaised >= 100) {
            statusClass = 'dotRed'
            status = 'ENDED'
            checkRaisedAmount()
        } else if (new Date().getTime() > data.endTime * 1000 || data.totalSupply < 0.00001) {
            statusClass = 'dotRed'
            status = 'ENDED'
            checkRaisedAmount()
        } else if (new Date().getTime() > data.startTime * 1000) {
            statusClass = 'dotGreen'
            status = 'LIVE'
            if (data.liquidation.isPaused) {
                title = `PDO is Paused due to low liquidity in pool`
                button = <NormalButton viewType={viewType} className='changeBtn'>Unpause PDO</NormalButton>
            } else if (data.percentAgeRaised < 100) {
                displayInput()
            }
        } else {
            statusClass = 'dotOrange'
            status = 'COMING SOON'
            ifoNotStartButton()
        }

        if (!(account && chainId === ACTIVE_NETWORK)) {
            button = (
                <div>
                    <UnlockWalletButton viewType={viewType} onClick={toggleWalletModal} className='changeBtn'>
                        {/* Unlock Wallet */}
                        Connect Your Wallet
                    </UnlockWalletButton>
                </div>
            )
        } else if (displayCounter === 0 && viewType === 1) {
            marginMiddle = <div style={{ height: '0px' }}>&nbsp;</div>
            marginBottom = <div style={{ height: '0px' }}>&nbsp;</div>
        }
    }
    displayButton()
    //todo to move seperate components
    const tokenInfoAddress = 'https://bscswap.info/token/' + data.poolInfo.offeringToken

    let linkAddress = ''
    let tokenLinkAddress = ''
    if (chainId === 97) {
        linkAddress = 'https://testnet.bscscan.com/address/' + data.pdoAddress
        tokenLinkAddress = 'https://testnet.bscscan.com/token/' + data.poolInfo.offeringToken
    } else if (chainId === 56) {
        linkAddress = 'https://bscscan.com/address/' + data.pdoAddress
        tokenLinkAddress = 'https://bscscan.com/token/' + data.poolInfo.offeringToken
    }
    const getTokenLogoURL = address => {



        return '';

    }

    const addDefaultSrc = ev => {
        ev.target.src = defaultImage
    }

    const dayToleft = endTime => {
        if (new Date().getTime() / 1000 > endTime) {
            return '(0 days left)'
        } else {
            let days = Math.ceil((endTime - new Date().getTime() / 1000) / 86400)
            return `(${days} days left)`
        }
    }
    // let tokenImage = getTokenLogoURL(data.poolInfo.offeringToken)
    // let tokenImage = defaultImage

    function genrateDetails(order) {
        return (
            <div style={{ width: '100%', maxWidth: '1600px' }}>
                <IFOMoreTitle viewType={viewType} >
                    PDO type{' '}
                    <IFOMoreTitleInner viewType={viewType}>
                        {data.pdoType === 'FIXSALE' ? 'Fix Sale' : 'Dutch Auction'}
                    </IFOMoreTitleInner>
                </IFOMoreTitle>

                {data.isDumperSheild &&
                    <IFOMoreTitle viewType={viewType} >
                        Dumper Sheild Unlock Date
                        <IFOMoreTitleInner viewType={viewType}>
                            {new Date(data.dsUnlockDate * 1000).toDateString()}
                        </IFOMoreTitleInner>
                    </IFOMoreTitle>
                }




                <IFOMoreTitle viewType={viewType}>
                    Fair PDO <Tooltip tip={'Fair PDOs burn leftover tokens from the PDO'} id={'m1'} />{' '}
                    <IFOMoreTitleInner viewType={viewType}>{data.fairPdo ? 'Yes' : 'No'}</IFOMoreTitleInner>
                </IFOMoreTitle>
                <IFOMoreTitle viewType={viewType}>
                    Start time <IFOMoreTitleInner viewType={viewType}>{formatTime(data.startTime)}</IFOMoreTitleInner>
                </IFOMoreTitle>
                <IFOMoreTitle viewType={viewType}>
                    End time <IFOMoreTitleInner viewType={viewType}>{formatTime(data.endTime)}</IFOMoreTitleInner>
                </IFOMoreTitle>

                {data.investInfo.limitType === 'USD_LIMIT' ? (
                    <>
                        <IFOMoreTitle viewType={viewType}>
                            Minimum Investment{' '}
                            <IFOMoreTitleInner viewType={viewType}> $ {data.investInfo.minInvest} </IFOMoreTitleInner>
                        </IFOMoreTitle>

                        <IFOMoreTitle viewType={viewType}>
                            Maximum Investment{' '}
                            <IFOMoreTitleInner viewType={viewType}> $ {data.investInfo.maxInvest} </IFOMoreTitleInner>
                        </IFOMoreTitle>
                    </>
                ) : data.investInfo.limitType === 'TOKEN_LIMIT' ? (
                    <>
                        <IFOMoreTitle viewType={viewType}>
                            Minimum Token Have to purchase{' '}
                            <IFOMoreTitleInner viewType={viewType}> {data.investInfo.minInvest} {data.tokenInfo.symbol.toUpperCase()}</IFOMoreTitleInner>
                        </IFOMoreTitle>

                        <IFOMoreTitle viewType={viewType}>
                            Maximum Token can purchase{' '}
                            <IFOMoreTitleInner viewType={viewType}> {data.investInfo.maxInvest} {data.tokenInfo.symbol.toUpperCase()}</IFOMoreTitleInner>
                        </IFOMoreTitle>
                    </>
                ) :
                    <>
                        <IFOMoreTitle viewType={viewType}>
                            Minimum Token Have to purchase{' '}
                            <IFOMoreTitleInner viewType={viewType}> No Limit</IFOMoreTitleInner>
                        </IFOMoreTitle>

                        <IFOMoreTitle viewType={viewType}>
                            Maximum Token can purchase{' '}
                            <IFOMoreTitleInner viewType={viewType}> No Limit</IFOMoreTitleInner>
                        </IFOMoreTitle>
                    </>
                }
                <IFOMoreTitle viewType={viewType}>
                    For Sale{' '}
                    <IFOMoreTitleInner viewType={viewType}>{convertToToken(data.poolInfo.supplyToDistribute)}</IFOMoreTitleInner>
                </IFOMoreTitle>

                {data.pdoType === 'AUCTION' &&
                    <>
                        <IFOMoreTitle viewType={viewType}>
                            Soft Cap{' '}
                            <IFOMoreTitleInner viewType={viewType}>(USD) ${convertToCurrency(data.softCap)}</IFOMoreTitleInner>
                        </IFOMoreTitle>


                        <IFOMoreTitle viewType={viewType}>
                            Hard Cap{' '}
                            <IFOMoreTitleInner viewType={viewType}>(USD) ${convertToCurrency((data.totalSupply + data.totalTokenPurchased) * data.offeringTokenPrice)}</IFOMoreTitleInner>
                        </IFOMoreTitle>
                    </>
                }

                <IFOMoreTitle viewType={viewType}>
                    Fix price (USD) per token <IFOMoreTitleInner viewType={viewType}>{data.offeringTokenPrice}</IFOMoreTitleInner>
                </IFOMoreTitle>

                <IFOMoreTitle viewType={viewType}>
                    Total Raised{' '}
                    <IFOMoreTitleInner viewType={viewType} className="orangeColor">
                        (USD) ${convertToCurrency(Number(data.liquidation.soldOutUsd.$numberDecimal))}
                    </IFOMoreTitleInner>
                </IFOMoreTitle>
                {/* <IFOMoreTitle viewType={viewType}>
                    Total Raised (% of target)<IFOMoreTitleInner viewType={viewType}>{`${data.percentAgeRaised.toFixed(2)}%`}</IFOMoreTitleInner>
                </IFOMoreTitle> */}
                {data.isStaking ? (
                    <>
                        <IFOMoreTitle viewType={viewType}>
                            Staking Period{' '}
                            <IFOMoreTitleInner viewType={viewType}>
                                {data.poolInfo.period} days {order !== null ? dayToleft(order.endDate) : ''}
                            </IFOMoreTitleInner>
                        </IFOMoreTitle>
                        <IFOMoreTitle viewType={viewType}>
                            APY <IFOMoreTitleInner viewType={viewType}>{convertToPercantage(data.poolInfo.apy)} %</IFOMoreTitleInner>
                        </IFOMoreTitle>
                    </>
                ) : (
                    <>
                        <IFOMoreTitle viewType={viewType}>
                            Staking Period <IFOMoreTitleInner viewType={viewType}>-</IFOMoreTitleInner>
                        </IFOMoreTitle>
                        <IFOMoreTitle viewType={viewType}>
                            APY <IFOMoreTitleInner viewType={viewType}>No Staking</IFOMoreTitleInner>
                        </IFOMoreTitle>
                    </>
                )}

                {order !== null ? (
                    <>
                        <IFOMoreTitle viewType={viewType}>
                            Your Investment{' '}
                            <IFOMoreTitleInner viewType={viewType}>$ {convertToCurrency(order.usdAmount)} </IFOMoreTitleInner>
                        </IFOMoreTitle>

                        <IFOMoreTitle viewType={viewType}>
                            Amount of tokens{' '}
                            <IFOMoreTitleInner viewType={viewType}>
                                {convertToToken(order.tokenAmount)} {data.tokenInfo.symbol.toUpperCase()}
                            </IFOMoreTitleInner>
                        </IFOMoreTitle>
                        <IFOMoreTitle viewType={viewType}>
                            Tokens from APY
                            <IFOMoreTitleInner viewType={viewType}>
                                {' '}
                                {convertToToken(order.tokenReward)} {data.tokenInfo.symbol.toUpperCase()}
                            </IFOMoreTitleInner>
                        </IFOMoreTitle>
                        <IFOMoreTitle viewType={viewType}>
                            Total tokens after APY
                            <IFOMoreTitleInner viewType={viewType}>
                                {' '}
                                {convertToToken(order.tokenAmount + order.tokenReward)}
                            </IFOMoreTitleInner>
                        </IFOMoreTitle>
                    </>
                ) : (
                    <>
                        <IFOMoreTitle viewType={viewType}>
                            Your Investment <IFOMoreTitleInner viewType={viewType}> - </IFOMoreTitleInner>
                        </IFOMoreTitle>
                        <IFOMoreTitle viewType={viewType}>
                            Final value after APY<IFOMoreTitleInner viewType={viewType}> - </IFOMoreTitleInner>
                        </IFOMoreTitle>
                        <IFOMoreTitle viewType={viewType}>
                            Tokens from APY
                            <IFOMoreTitleInner viewType={viewType}>
                                {' '}
                                - {data.tokenInfo.symbol.toUpperCase()}
                            </IFOMoreTitleInner>
                        </IFOMoreTitle>
                        <IFOMoreTitle viewType={viewType} style={{ borderBottom: "0px" }}>
                            Total tokens after APY
                            <IFOMoreTitleInner viewType={viewType}>
                                {' '}
                                -
                            </IFOMoreTitleInner>
                        </IFOMoreTitle>
                    </>
                )}

                <IFOMoreTitleV2 viewType={viewType} style={{ marginTop: '7px' }}>Fundraising split</IFOMoreTitleV2>
                <IFOMoreTitle viewType={viewType}>
                    Project <IFOMoreTitleInner viewType={viewType}>{data.investInfo.cashOutRatio}%</IFOMoreTitleInner>
                </IFOMoreTitle>
                <IFOMoreTitle viewType={viewType}>
                    Dumper Shield <IFOMoreTitleInner viewType={viewType}>{data.investInfo.dumperShieldRatio}%</IFOMoreTitleInner>
                </IFOMoreTitle>
                <IFOMoreTitle viewType={viewType}>
                    Liquidity pool
                    <IFOMoreTitleInner viewType={viewType}>
                        {100 - (data.investInfo.cashOutRatio + data.investInfo.dumperShieldRatio)}%
                    </IFOMoreTitleInner>
                </IFOMoreTitle>

                {(account?.toLowerCase() === data.owner && data.totalSupply > 0) &&
                    <IFOMoreTitle>
                        <IFOMoreTitleInner>
                            <IDOWidrowBTN onClick={claimUnsold} >Withdraw tokens </IDOWidrowBTN>
                        </IFOMoreTitleInner>
                    </IFOMoreTitle>
                }
                {/* <IFOBTNBAR02 style={{ borderBottom: "1px solid #393d46", marginTop: "0px", padding: "12px 0px" }}>
                    <IFOBTN04 href={getEtherscanLink(chainId, data.poolInfo.offeringToken, "token")} target="_blank">
                        View Project Info <i className="fas fa-external-link-alt"></i>
                    </IFOBTN04>

                    <IFOBTN03 href={`https://www.dextools.io/app/bsc/pair-explorer/${data.poolInfo.lpAddress}`} target="_blank">
                        View project liquidity <i className="fas fa-external-link-alt"></i>
                    </IFOBTN03>
                </IFOBTNBAR02> */}
            </div>
        )
    }

    function Detail() {
        return displayOrders.length === 0 ? (
            genrateDetails(null)
        ) : (
            <SliderDiv ref={SliderDivRef}>
                {genrateDetails(displayOrders[currentOrder])}
                <SliderUL>
                    {displayOrders.map((order, i) => {
                        return (
                            <li onClick={() => slideSwaped(i)} className={currentOrder === i ? 'order-active' : ''}>
                                {' '}
                                {order.isFilled ? <i className='fas fa-check'></i> : <button></button>}
                            </li>
                        )
                    })}
                </SliderUL>
            </SliderDiv>
        )
    }

    if (viewType === 1) {
        return (
            <TVShow
                active={activeDiv}
                unlock={!(account && chainId === ACTIVE_NETWORK)}
            // onMouseEnter={() => {
            //     setActiveDiv(true)
            // }} onMouseLeave={() => {
            //     setActiveDiv(false)
            // }}
            >
                {showConfirm && (
                    <TransactionConfirmationModal
                        isOpen={showConfirm}
                        startMark={startMark}
                        onDismiss={handleDismissConfirmation}
                        attemptingTxn={txHash === '' ? true : false}
                        hash={txHash}
                        content={() => <></>}
                        pendingText="Waiting for Confirmation"
                    />
                )}
                <IFOMbox01>
                    <IFObox01>
                        <Image onError={addDefaultSrc} src={tokenImage} style={{ width: '76px', height: '76px' }}></Image>
                    </IFObox01>
                    <IFObox02>
                        {data.tokenInfo.symbol.toUpperCase()}
                        <SpanBox2>{data.tokenInfo.name}</SpanBox2>
                    </IFObox02>
                    <IFObox03>
                        {data.pdoType === 'FIXSALE' ? <>
                            Fix Sale
                            <Tooltip
                                tip={
                                    'Traditional offering with fixed price to the market, which means every investment receives all their tokens at the same price, without slippage.'
                                }
                                id={'m1'}
                            /></>
                            :
                            <>
                                Dutch Auction
                                <Tooltip
                                    tip={
                                        'A Dutch Auction allows the market to set the price once the soft cap is reached. <p>Example</p> <p>1) A project has a token supply of 10,000,000 with a hard cap of $100,000 ($0.01 per token) and a soft cap of  $10,000</p><p>2) Once the time period is over, and assuming the soft cap of $10,000 is reached, the smart contract will give away the tokens, even without hitting the hard cap</p> <p>3) If a total of $10,000 is invested, the smart contract will distribute the 10,000,000 tokens on a pro-rata basis, making the price $0.001 per token </p>'
                                    }
                                    id={'m1'}
                                />
                            </>}
                        <SpanAPY>
                            {data.isStaking ? (
                                <span className="smtxt01">
                                    {data.poolInfo.apy}% APY / {data.poolInfo.period} days
                                </span>
                            ) : (
                                'No Staking'
                            )}
                        </SpanAPY>

                        {data.isDumperSheild && (
                            <>
                                <span className="smtxt02">
                                    <i className="fas fa-shield-alt"></i> Dumper Shield
                                    <Tooltip
                                        tip={
                                            'Tokens behind the dumper shield have two options.<p>1) OTC</p><p>2) Through the Dumper Shield gateway</p>'
                                        }
                                        id={'m1'}
                                    />
                                </span>
                                <br></br>
                            </>
                        )}

                        {data.fairPdo && (
                            <>              <span className="smtxt02">
                                <i className="fas fa-fire"></i> Fair PDO <Tooltip tip={'Fair PDOs burn leftover tokens from the PDO'} id={'m1'} />
                            </span>
                                <br></br>
                            </>

                        )}

                        {data.investInfo.isKyc && (
                            <span className="smtxt02">
                                <i className="fas fa-check"></i> KYC
                            </span>
                        )}

                        {/* <SpanAPY>
                            <span className='smtxt01'><i className="fas fa-shield-alt"></i> Dumper Shield<ToolTip tip={"Coming Soon"} id={'m1'} /></span>
                        </SpanAPY> */}
                    </IFObox03>
                </IFOMbox01>
                <IFOGraph>
                    <i>Total investment {convertToPercantage(Number(data.percentAgeRaised))}%</i>
                    <SpanIFOGraph className='ani-1' style={{ width: data.percentAgeRaised + '%' }}></SpanIFOGraph>
                </IFOGraph>
                <IFOTitle01>{title}</IFOTitle01>
                {(displayCounter === 1 || displayCounter === 2) && (
                    <IFOCounter01>
                        <IFOCounter counterTime={counterTime} viewType={viewType}></IFOCounter>
                    </IFOCounter01>
                )}

                {displayCounter === 3 && <IDOTitle1>{titleRed} </IDOTitle1>}

                {marginTop}

                {account && chainId === ACTIVE_NETWORK
                    ? displayCounter === 1 && (
                        <IFOTitle01>
                            1 BNB = ${price}, 1 {data.tokenInfo.symbol.toUpperCase()} = ${data.offeringTokenPrice}
                        </IFOTitle01>
                    )
                    : null}
                {marginMiddle}
                {(displayCounter === 3) ? claimButton() : button}

                {(displayCounter === 3 && account?.toLowerCase() === data.owner && data.totalSupply > 0.0001) &&
                    <NormalButton viewType={viewType} onClick={claimUnsold}> Claim unslold token</NormalButton>}

                {marginBottom}
                <IFOBarBTN01>
                    <IFOSBTN01Link href="#" viewType={viewType} target="_blank">
                        <i className={statusClass}></i> {status} #{data.pdoNumber}
                    </IFOSBTN01Link>

                    <IFOSBTN02Link
                        viewType={viewType}
                        onClick={() => {
                            setIsOpened(!isOpened)
                            if (isOpened === true) {
                                setDetails('Details')
                                setDetailsClass('fas fa-chevron-down')
                            } else {
                                setCurrentOrder(0)
                                setDetails('Hide')
                                setDetailsClass('fas fa-chevron-up')
                            }
                        }}
                    >
                        <SpanTxn01>
                            {details} <i className={detailsClass}></i>
                        </SpanTxn01>
                    </IFOSBTN02Link>
                </IFOBarBTN01>

                <Collapse isOpened={isOpened}>
                    {isOpened && <Detail />}
                    <IFOBTNBAR02>
                        <IFOBTN04 href={getEtherscanLink(chainId, data.poolInfo.offeringToken, "token")} target="_blank">
                            View Project Info <i className="fas fa-external-link-alt"></i>
                        </IFOBTN04>

                        <IFOBTN03 href={`https://www.dextools.io/app/bsc/pair-explorer/${data.poolInfo.lpAddress}`} target="_blank">
                            View project liquidity <i className="fas fa-external-link-alt"></i>
                        </IFOBTN03>
                    </IFOBTNBAR02>
                </Collapse>

                {data.isStaking && isFutureBarOpen && (
                    <FutureBar
                        balance={balance}
                        price={price}
                        data={data}
                        orders={orders}
                        isFutureBarOpen={isFutureBarOpen}
                        setIsFutureBarOpen={setIsFutureBarOpen}
                        setPopUpMsg={setPopUpMsg}
                        setIsTxFailed={setIsTxFailed}
                        setTxPopUp={setTxPopUp}
                        userUsdLimit={userUsdLimit}
                        userTokenLimit={userTokenLimit}
                        tokenLogo={tokenImage}
                        calculateMaxToken={calculateMaxToken}
                    />
                )}
                {txPopUp && <ConfirmTxPopup isOpen={txPopUp} setIsOpen={closePopUp} message={popUpMsg} isFail={isTxFailed} />}

                {(data.investInfo.isKyc) && <KycConfimation isOpen={isKycPopup} callBack={handleKyc} pdoAddress={data.pdoAddress} tokenSymbol={data.tokenInfo.symbol}></KycConfimation>}

            </TVShow>
        )
    } else {
        return (
            <>
                {showConfirm && (
                    <TransactionConfirmationModal
                        isOpen={showConfirm}
                        startMark={startMark}
                        onDismiss={handleDismissConfirmation}
                        attemptingTxn={true}
                        hash={''}
                        content={() => <></>}
                        pendingText="Waiting for Confirmation"
                    />
                )}
                <RowTR>
                    <RowTD>
                        <Box01>
                            <Image onError={addDefaultSrc} src={tokenImage}></Image>
                        </Box01>
                        <Box02>
                            {data.tokenInfo.symbol.toUpperCase()}
                            <SpanBoxList>{data.tokenInfo.name.toUpperCase()}</SpanBoxList>
                        </Box02>
                    </RowTD>
                    <RowTD>
                        <BoxIFOType>
                            {data.pdoType === 'FIXSALE' ? 'Fix Sale' : 'Dutch Auction'}
                            <Tooltip tip={'Traditional offering with fixed price to the market, which means every investment receives all their tokens at the same price, without slippage.'} id={'m1'} />
                            {/* tooltip for dutch auction */}
                            {/* <Tooltip
                                tip={
                                    'A Dutch Auction allows the market to set the price once the soft cap is reached. <p>Example</p> <p>1) A project has a token supply of 10,000,000 with a hard cap of $100,000 ($0.01 per token) and a soft cap of  $10,000</p><p>2) Once the time period is over, and assuming the soft cap of $10,000 is reached, the smart contract will give away the tokens, even without hitting the hard cap</p> <p>3) If a total of $10,000 is invested, the smart contract will distribute the 10,000,000 tokens on a pro-rata basis, making the price $0.001 per token </p>'
                                }
                                id={'m1'}
                            /> */}
                            <SpanAPYList>
                                {data.isStaking ? (
                                    <span className="smtxt01">
                                        {data.poolInfo.apy}% APY
                                        <br />
                                        {data.poolInfo.period} days
                                    </span>
                                ) : (
                                    'No Staking'
                                )}
                            </SpanAPYList>
                        </BoxIFOType>
                    </RowTD>
                    <RowTD>
                        <GraphText>Total investment {convertToPercantage(Number(data.percentAgeRaised))}%</GraphText>
                        <IFOGraphList>
                            <SpanIFOGraph style={{ width: `${data.percentAgeRaised.toFixed(2)}%` }}></SpanIFOGraph>
                        </IFOGraphList>
                    </RowTD>
                    <RowTD>
                        {(displayCounter === 1 || displayCounter === 2) && (
                            <IFOCounter01 style={{ justifyContent: "flex-start" }}>
                                <IFOCounter counterTime={counterTime} viewType={viewType}></IFOCounter>
                            </IFOCounter01>
                        )}

                        {displayCounter === 3 && <IDOTitle1 style={{ textAlign: "left" }}>{titleRed} </IDOTitle1>}


                    </RowTD>
                    <RowTD>
                        <IFOSBTN01Link href="" viewType={viewType} target="_blank">
                            <i className={statusClass}></i> {status} #{data.pdoNumber}
                        </IFOSBTN01Link>
                        <IFOSBTN02Link
                            viewType={viewType}
                            onClick={() => {
                                setIsOpened(!isOpened)
                                if (isOpened === true) {
                                    setDetails('Details')
                                    setDetailsClass('fas fa-chevron-down')
                                } else {
                                    setDetails('Hide')
                                    setDetailsClass('fas fa-chevron-up')
                                }
                            }}
                        >
                            <SpanTxn01>
                                {details} <i className={detailsClass}></i>
                            </SpanTxn01>
                        </IFOSBTN02Link>
                    </RowTD>
                    <RowTD>
                        {displayCounter === 3 ? claimButton() : button}
                    </RowTD>
                </RowTR>
                {isOpened && (
                    <RowTR>
                        <RowTD colSpan={6}>
                            <Detail />
                        </RowTD>
                    </RowTR>
                )}

                {data.isStaking && isFutureBarOpen && (
                    <FutureBar
                        balance={balance}
                        price={price}
                        data={data}
                        orders={orders}
                        isFutureBarOpen={isFutureBarOpen}
                        setIsFutureBarOpen={setIsFutureBarOpen}
                        setPopUpMsg={setPopUpMsg}
                        setIsTxFailed={setIsTxFailed}
                        setTxPopUp={setTxPopUp}
                        userUsdLimit={userUsdLimit}
                        userTokenLimit={userTokenLimit}
                        tokenLogo={tokenImage}
                        calculateMaxToken={calculateMaxToken}
                    />
                )}

                {(data.investInfo.isKyc) && <KycConfimation isOpen={isKycPopup} callBack={handleKyc} pdoAddress={data.pdoAddress} tokenSymbol={data.tokenInfo.symbol}></KycConfimation>}

                {txPopUp && <ConfirmTxPopup isOpen={txPopUp} setIsOpen={setTxPopUp} message={popUpMsg} isFail={isTxFailed} />}

            </>
        )
    }
}

///////////////List View/////////////////////////////////
const CmgSoon = styled.div`
  color: #e4ad0c;
  font-size: 20px;
  line-height: 50px;
  font-weight: bold;
  margin: 40px 0;
`

const PdoInactive = styled.div`
  margin: 50px 0;
`

const SliderDiv = styled.div`
  .example-enter {
    opacity: 0.01;
  }

  .example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  .example-leave {
    opacity: 1;
  }

  .example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`

const SliderUL = styled.ul`
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
  display: block;
  padding: 0;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    width: 12px;

    button {
      width: 9px;
      height: 12px;
      background-color: #393d46;
      border: none;
      outline: none;
      margin-bottom: 6px;
    }

    button:hover,
    &.order-active button {
      background-color: #00d426;
    }
    button::after,
    button::before {
      display: none !important;
    }
  }
`

const GraphText = styled.div`
  font-size:11px; font-weight:normal; color:${({ theme }) => theme.text6};
`;

const IFOGraphList = styled.div`
  max-width: 177px;
  width: 100%;
  margin: 6px 0 6px 0;
  background-color: #393d46;
  height: 10px;
  /* background-color: #dedede; */
  border-radius: 30px;
  overflow: hidden;
  position: relative;
`

const SpanAPYList = styled.span`
  font-weight: 400;
  display: block;
  color: #6c7076 !important;
`
const BoxIFOType = styled.div`
    width: auto;
    color: #00f02b;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    padding-left: 0;
    border-left: none;
    white-space: nowrap;
    i{font-size:10px;}
}
`
const SpanBoxList = styled.span`
  font: normal 12px/20px 'IBM Plex Mono', arial;
  display: block;
`
const RowTR = styled.tr`
  border-bottom: 1px solid #393d46;
`
const RowTD = styled.td`
  vertical-align: middle;
  text-align: left;
  color: ${({ theme }) => theme.ifoText1} !important;

  .slick-dots li button {
    width: 9px;
    height: 9px;
    background-color: #393d46;
    border: none;
    outline: none;
    margin-bottom: 6px;
  }

  .slick-dots li button:hover,
  .slick-dots li.slick-active button {
    background-color: #00d426;
  }
  .slick-dots li button::after,
  .slick-dots li button::before {
    display: none !important;
  }

  .slick-dots li {
    width: 12px;
  }
`
const Box01 = styled.div`
  width: 23px;
  height: 23px;
  border: 1px solid #000;
  display: inline-block;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`

const Box02 = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  width: auto;
  font-size: 14px;
  line-height:14px;
  font-weight:bold;
  padding-left: 13px;
  display: inline-block;
`
const BTMRow = styled.div<{ viewType?: number }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${({ viewType }) =>
        viewType === 2 &&
        css`
     justify-content:flex-start;
    `}
`

const IFOCounter02 = styled.div`
  font-weight: 700;
  margin: 10px 0;
  font-size: 18px;
  color: ${({ theme }) => theme.ifoText1};
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
`

const InputBar01 = styled.div<{ viewType?: number }>`
  display: flex;
  width: 100%;
  margin: ${({ viewType }) => (viewType === 1 ? '5px auto' : 'margin: 0 0 0 auto')};
  align-items: center;
  justify-content: center;
  border: 2px solid #5a5e67;
  border-radius: 10px;
  ${({ viewType }) =>
        viewType === 2 &&
        css`
      max-width: 268px; justify-content:space-between;
    `}
    input{
        ${({ viewType }) =>
        viewType === 2 &&
        css`
            width:inherit;
        `}
    }
`

// width: 34px;
// height: 34px;
// background-color: #5a5e67;
// border-radius: 4px 0 0 4px;
// display: flex;
// align-items: center;
// justify-content: center;
// padding: 7px;

const IdoFuture = styled.div`
  cursor: pointer;
  color: #00f02b;
  transform: scale(0.7);
  transform-origin: right;
  font-size: 17px;
  line-height: 31px;

  .switch {
    position: relative;
    margin-left: 7px;
    margin-right: 7px;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #00f02b;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #00f02b;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`
const SpanIDOBar = styled.span<{ viewType?: number }>`
  width: ${({ viewType }) => (viewType === 1 ? '75px' : '105px')};
  height: ${({ viewType }) => (viewType === 1 ? '55px' : '34px')};
  padding-left: 14px;
  background-color: #5a5e67;
  border-radius: 4px 0 0 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ viewType }) =>
        viewType === 2 &&
        css`
      padding: 7px;
    `}
    img{width:20px;}
`
const DDLinkbtn = styled.a<{ viewType?: number }>`
        width:9px; height:9px; display:block; position:absolute; right:7px; top:calc(50% - 7px); color:#fff;
        &:hover{
            color:#12151b;
        }

    }
    `
const BinanceImage = styled.img`
  max-width: 100%;
  height: auto;
`

// width: 74px;
// font: 400 9px/20px 'Press Start 2P', monospace, arial;
// border-radius: 0 4px 4px 0;
// padding: 7px 0;
// color: #fff;
// background-color: #febb00;
//     border: none;
//     outline: none !important;

const InvestButton = styled.button<{ viewType?: number }>`
  width: ${({ viewType }) => (viewType === 1 ? '125px' : '105px')};
  background-color: #00f02b;
  border: none;
  outline: none !important;
  font: 400 ${({ viewType }) => (viewType === 1 ? '14px/29px' : '9px/20px')} 'Press Start 2P', monospace, arial;
  border-radius: 0 4px 4px 0;
  padding: ${({ viewType }) => (viewType === 1 ? '13px' : '7px')} ${({ viewType }) => (viewType === 1 ? '0px' : '25px')};
  color: #fff;

  :hover {
    background-color: #00d426;
  }
`

const IDOEstimatedCoins = styled.div`
  font-size: 12px;
  color: #8e9195;
  margin-left: auto;
`

const IDOMaxBtn = styled.a`
  display: flex;
  font-size: 12px;
  color: #8e9195;
  text-align: left;
  margin-top:5px;
  p{margin-top:5px;}
`

const IDOWidrowBTN = styled.a`
  color: #C32B1C;
  display: inline-bottom;

  :hover {
    text-decoration: underline;
  }
`

///////////////////////////////////////////////////////////////
const IFOCounter01 = styled.div`
  font-weight: 700;
  margin: 10px 0;
  font-size: 18px;
  color: ${({ theme }) => theme.ifoText1};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const TVShow = styled.div<{ active: boolean; unlock: boolean }>`
  //active
  box-shadow: ${({ active }) => (active ? '0 0 25px 1px rgba(0,240,43,0.4)' : '')};
  &:hover {
    box-shadow: 0 0 25px 1px rgba(0, 240, 43, 0.4);
  }
  padding: 30px;
  background-color: ${({ theme }) => theme.ifoText2};
  border: 1px solid #1c1c1c;
  margin: 16px;
  width: calc(33.33% - 32px);
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  /* -webkit-box-shadow: 0 0 25px 1px rgba(0,0,0,0.05);
    box-shadow: 0 0 25px 1px rgba(0,0,0,0.05); */
  min-height: ${({ unlock }) => (unlock ? '200px' : '450px')};
  ${Media.md} {
    width: calc(50% - 32px);
  }
  ${Media.sm} {
    width: calc(100% - 32px);
  }
  ${Media.xs} {
    width: calc(100% - 20px);
    margin: 10px;
    padding: 15px;
  }

  .slick-dots li button {
    width: 9px;
    height: 9px;
    background-color: #393d46;
    border: none;
    outline: none;
  }

  .slick-dots li button:hover,
  .slick-dots li.slick-active button {
    background-color: #00d426;
  }
  .slick-dots li button::after,
  .slick-dots li button::before {
    display: none !important;
  }
  .slick-dots li {
    width: 12px;
  }
`
const IFOMbox01 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${Media.xs} {
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const IFObox01 = styled.div`
  width: 78px;
  height: 78px;
  border-radius: 50%;
  border:2px solid #A9A9A9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${Media.xs} {
    width: 60px;
    height: 60px;
  }
`

//font: normal 16px/24px 'Press Start 2P', arial;

const IFObox02 = styled.div`
  width: calc(64% - 78px);
  color: ${({ theme }) => theme.ifoText1};
  padding-left: 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  ${Media.xs} {
    width: calc(100% - 60px);
  }
`
const IFObox03 = styled.div`
  width: 36%;
  color: #00f02b;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  padding-left: 20px;
  border-left: 1px solid #393d46;
  white-space: nowrap;

  ${Media.xs} {
    width: 100%;
    border-left: 0px;
    padding-top: 6px;
    border-top: 1px solid #393d46;
    margin-top: 7px;
    padding-left: 80px;
  }

  .smtxt02 {
    font-size: 12px;
  }
`

const Image = styled.img`
  max-width: 100%;
  height: auto;
`

const SpanBox2 = styled.span`
  font: normal 14px/20px 'IBM Plex Mono', arial;
  display: block;
`
const SpanAPY = styled.span`
  font-weight: 400; line-height:normal;
  display: block;
  color: ${({ theme }) => theme.ifoText1};
  & .smtxt01 {
    font-size: 11px; color: ${({ theme }) => theme.text6};
  }
`

const IFOGraph = styled.div`
  width: 100%;
  margin: 30px 0 20px 0;
  height: 25px;
  background-color: #393d46;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  & i {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    font-style: normal;
    color: #6c7076;
    z-index: 2;
    padding-top: 7px;
    font-size: 11px;
  }
`

const SpanIFOGraph = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #00f02b;
    border-radius: 30px;
    width:0;


}
`
const IFOTitle01 = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
`
/*

background-color: #ffa600;
    display: block;
    padding: 8px 1px;
    border-radius: 6px;
    text-align: center;
    font: 400 9px/14px 'Press Start 2P', arial;
    margin: 2px 0;
    max-width: 268px;
*/

const BaseButton = styled.a<{ viewType?: number }>`
  display: block;
  text-align: center;
  padding: ${({ viewType }) => (viewType === 1 ? '17px 8px' : '10px 1px')};
  border-radius: ${({ viewType }) => (viewType === 1 ? '10px' : '6px')};
  font: bold ${({ viewType }) => (viewType === 1 ? '18px/28px' : '14px/18px')} 'IBM Plex Mono', arial, sans-serif;
  margin: ${({ viewType }) => (viewType === 1 ? '28px 0px 20px' : '2px 0')};
  ${({ viewType }) =>
        viewType === 2 &&
        css`
      max-width: 268px; min-width:268px;
    `}
`

const NormalButton = styled(BaseButton)`
  background-color: #5d6168;
  color: #febb00;
  :hover {
    background-color: #898b90;
    color: #febb00;
  }
`

const GreenButton = styled(BaseButton)`
  background-color: #8db610;
  color: #fff !important;

  :hover {
    background-color: #5d6168;
    color: #fff !important;
  }
`

const UnsoldButton = styled(BaseButton)`
  background-color: #5d6168;
  color: #fff !important;

  :hover {
    background-color: #f3b92e;
    color: #fff !important;
  }
`

const UnlockWalletButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.ConnectButtonBG};
  :hover {
    background-color: rgb(49, 49, 49);
  }
`

const DisableButton = styled(BaseButton)`
  color: #c32b1c;
  background-color: #383b42;

  :hover {
    background-color: ${({ theme }) => theme.ConnectButtonBG};
    color: #77150b;
  }
`
const IFOBarBTN01 = styled.div`
  border-top: 1px solid #373b43;
  width: 100%;
  padding: 25px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`
// const IFOSBTN01Link = styled.a`
//     margin-right: auto;
//     color: #0000ff;
//     font-size: 14px;
//     font-weight: 700;
// `

const IDOTitle1 = styled.div`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #c32b1c;
  line-height: 24px;
  padding: 22px 4px;
`
const IDOTitle2 = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #c32b1c;
  /* line-height: 24px; */
  /* padding: 22px 4px; */
`
const IFOSBTN02Link = styled.a<{ viewType?: number }>`
  margin-left: auto;
  color: ${({ theme }) => theme.ifoText1};
  font-size: ${({ viewType }) => (viewType === 1 ? '14px' : '12px')};
  font-weight: 700;
  display: inline-flex;
`

const IFOSBTN01Link = styled.a<{ viewType?: number }>`
  margin-right: ${({ viewType }) => (viewType === 1 ? 'auto' : '20px')};
  top: ${({ viewType }) => (viewType === 1 ? 'auto' : '2px')};
  position: relative;
  color: ${({ theme }) => theme.ifoText1};
  font-size: 14px;
  font-size: ${({ viewType }) => (viewType === 1 ? '14px' : '12px')};
  display: ${({ viewType }) => (viewType === 1 ? 'flex' : 'inline-flex')};
  font-weight: 700;
  align-items: center;
  justify-content: flex-start;

  i {
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 8px;
    margin: 0 12px 0 0;
  }
  i.dotGreen {
    background-color: #00f02b;
  }
  i.dotRed {
    background-color: #c32b1c;
  }
  i.dotOrange {
    background-color: #ffbb00;
  }
`

const SpanTxn01 = styled.span`
  display: block;
  i{font-size:12px; margin-left:5px;}
`;

const IFOMoreTitle = styled.span<{ viewType?: number }>`
  display: flex;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  color: #8e9195;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 6px 0;
  ${({ viewType }) =>
        viewType === 2 &&
        css`
        //margin-left: 30px;
        border-bottom:1px solid #393d46; padding:12px 0px;
    `}
    :first-child{
        ${({ viewType }) =>
        viewType === 1 &&
        css`
            margin-top:15px;
        `}
    }
`

const IFOMoreTitleV2 = styled.span<{ viewType?: number }>`
  display: flex;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  align-items: center;
  color: #8e9195;
  text-decoration: underline;
  padding-top: 22px;
  justify-content: flex-start;
  padding: 6px 0;
  ${({ viewType }) =>
        viewType === 2 &&
        css`
        //margin-left: 30px;
        border-bottom:1px solid #393d46; padding:12px 0px;
    `}
`

const IFOMoreTitleInner = styled.span<{ viewType?: number }>`
  margin-left: auto;
  ${({ viewType }) =>
        viewType === 2 &&
        css`
      margin-right: 30px;
    `}

  ${Media.xs} {
    width: 100%;
    padding-top: 6px;
    font-weight: 700;
    color: #fff;
    word-break: break-all;
  }
`

const IFOMoreTitleInnerLink = styled.a<{ viewType?: number }>`
  margin-left: auto;
  color: #8e9195;
  ${({ viewType }) =>
        viewType === 2 &&
        css`
      margin-right: 65px;
    `}
  ${Media.xs} {
    font-weight: 700;
    color: #fff;
    word-break: break-all;
  }
`

const IFOBTNBAR02 = styled.div`
  border: 0;
  margin-top: 30px;
  padding-bottom: 0;
  /* border-top: 1px solid #d6d6d6; */
  width: 100%;
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
`

const IFOBTN03 = styled.a`
  margin-left: auto;
  color: #00f02b;
  font-size: 12px;
  font-weight: 500;

  :hover {
    color: #ffffff;
  }
`

const IFOBTN04 = styled.a`
  margin-right: auto;
  color: #00f02b;
  font-size: 12px;
  font-weight: 500;

  :hover {
    color: #ffffff;
  }
`
