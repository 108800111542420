import React from 'react'
import styled from 'styled-components'
import FooteLinkIcon from '../../assets/images/footerLink_icon.png'
import FighterIcon from '../../assets/images/fighter-icon.png'
import BLogo from '../../assets/images/binance-logo.png'
import ELogo from '../../assets/images/etherLOGO.png'
import OtmLogo from '../../assets/images/otum-logo.png'
import Media from '../../theme/media-breackpoint'
import './Footer.css'
import DexIco from '../../components/DexICO';

    
const Link02: React.FC = () => {
    return (
        <FLink02B>
            <StyledAbsoluteLinkB href="#" >Powered by Atom Foundation:</StyledAbsoluteLinkB> |

            {/* <StyledAbsoluteLinkB href="#" >BSCbonus.com</StyledAbsoluteLinkB> | */}
            <StyledAbsoluteLinkB href="#" >SmartSwap.exchange</StyledAbsoluteLinkB> |
            <StyledAbsoluteLinkB href="https://jointer.io" target="_blank">Jointer.io</StyledAbsoluteLinkB> |
            <StyledAbsoluteLinkB href="#" >NFT.mx </StyledAbsoluteLinkB> |

            <StyledAbsoluteLinkB href="https://shield.pdo.finance/" >Dumper Shiled </StyledAbsoluteLinkB> |
            <StyledAbsoluteLinkB href="https://degenswap.io/" >DegenSwap.io</StyledAbsoluteLinkB> |
            <StyledAbsoluteLinkB href="#" >Freez.Finance</StyledAbsoluteLinkB> |
            <StyledAbsoluteLinkB href="https://cbdc.science/" >CBDC.science</StyledAbsoluteLinkB>

        </FLink02B>
    )
}

const Link01: React.FC = () => {
    return (
        <FLink01B>
            <StyledAbsoluteLinkA href="#" className="orangeColor" target="_blank" ><span className='show'>Free license</span><span className='cmgSoon'> Coming soon</span></StyledAbsoluteLinkA> |
            <StyledAbsoluteLinkA href="#" target="_blank" ><span className='show'>Buy PDO token</span><span className='cmgSoon'> Coming soon</span></StyledAbsoluteLinkA> |
            <StyledAbsoluteLinkA href="#" target="_blank" ><span className='show'>100% gas and fees reimbursement</span><span className='cmgSoon'> Coming soon</span></StyledAbsoluteLinkA> 
            {/* <StyledAbsoluteLinkA href="#" target="_blank" >KYC/AML</StyledAbsoluteLinkA> */}
        </FLink01B>
    )
}

const StyledIcon01 = styled.a`
    cursor:pointer; 
    text-decoration: none;
    width:25px;
    height:25px;
    margin:0 16px;
    display:inline-block;
    background:url(${FooteLinkIcon}) 50% 50% no-repeat;
`
const StyledAbsoluteLinkA = styled.a`
    cursor:pointer; 
    text-decoration: none;
    color:#fff; font-size:16px; font-weight: 700; font-family:'IBM Plex Mono',  arial;
    margin:0 16px;
    ${Media.xs}{text-align:left; }
    &:hover {  color: #00f02b; }
    &:hover .cmgSoon{   display:block; }
    &:hover .show{   display:none; }
    @media (max-width: 767px){
        margin: 0 6px;
    }
    @media (max-width: 991px) and (min-width: 768px){
        margin: 0 6px;
    }
    .cmgSoon{
        display:none;
    }
`
const StyledAbsoluteLinkB = styled.a`
    cursor:pointer; 
    text-decoration: none;
    color:#fff;
    font:normal 12px/16px "IBM Plex Mono", arial;
    margin:0 6px;
    &:hover {
        color: #00f02b;
    }
    @media (max-width: 767px){
        margin-bottom:0px;
    }
`

const FooterFrame = styled.footer`
`
const FooterWrapper = styled.footer`
    background-color: #070707;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 28px 20px;
    flex-wrap: wrap;
    position: relative;

    @media (min-width: 320px) and (max-width: 500px){
        flex-wrap: wrap;
        flex-direction: row;
        height: auto;
    }
    @media (min-width: 501px) and (max-width: 767px){
        height:auto;
        padding:10px 10px 30px 10px;
    }
    @media (min-width: 768px) and (max-width: 991px){
        height:auto;
        padding:10px 10px 30px 10px;
    } 
`

const JRLogo = styled.div`
    margin-right:auto;
    min-width:110px;

    ${Media.md}{ position:relative;  order: 2;  margin:10px 0 25px 0; 
    
        img{  position: absolute !important; left: 10px !important;  top: -90px !important; 
            ${Media.xs}{ top: -83px !important;  max-width: 98px; }
        }
    
    }

`

const FooterCbox = styled.div`
    text-align:center;
    margin:0 auto;
    position:relative; left:50px;
   
  ${Media.xl}{ max-width:960px; }
  ${Media.lg}{ max-width:760px;}
  ${Media.md}{ max-width:none; width: 100%; left:auto;  order: 3;  margin: 40px 0 0 0;}
  ${Media.sm}{ width: 100%; left:auto;  order: 1;  margin:10px 0 75px 0; }
  
  
`

const FLink01B = styled.div`
    font:normal 15px/16px arial;color:#424242;
    padding-bottom:14px;
    display:flex;
    align-items:center;
    justify-content:center;
    @media (max-width: 767px){
        color: #000;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
    }

`

const FLink02B = styled.div`
    font:normal 15px/16px arial;color:#2a2a2a;
    padding-bottom:8px;
    @media (max-width: 767px){
        color: #000;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        margin-top: 30px;
    }
`

const BinanceLogo = styled.div`
    margin-left:auto; 
    ${Media.sm}{ margin:0 0 20px auto; order:3; }


`

const BinanceLogoLink = styled.a`
    filter:grayscale(2); opacity:0.6;
    &:hover {  opacity:1;   }
`

const EthLogoLink = styled.a`

    margin-right:15px;
    position:relative; 

    &:after{ content:""; width:1px; height:34px; background-color:#2a2a2a; display:block; border:none;  position:absolute; right:-5px; top:-18px; }    

    &:hover {
        opacity:0.7;
    }
`

// const FooterBody = styled.div`
//     display:block;
//     margin: 8px;
//     width:99%;
//     position: fixed;
//     left: 0;
//     bottom: 0;


// `
const TextFooter = styled.span`
    width: 33.33%;
    text-align: center;
`
const OtmLogoLink = styled.a`
    display: flex;
    align-items: center;
    width: 33.33%;
    span {
        color: #909497;
        margin-right: 18px;
    }
`
const Footer: React.FC = () => {

    return (
        <FooterFrame>
            <DexIco />
            <FooterWrapper>
                <JRLogo>
                    <img style={{ position: 'absolute', left: 10, top: -52 }} src={FighterIcon} alt="Fighter" />
                </JRLogo>
                <FooterCbox>
                    <Link01 />
                    <Link02 />
                </FooterCbox>
                <BinanceLogo>

                    
                    <BinanceLogoLink href="https://www.binance.org/en" target="_blank"><img src={BLogo} alt="Binance Logo" /> </BinanceLogoLink>
                </BinanceLogo>
            </FooterWrapper>

            {/* Licence page footer */}
            {/* <FooterWrapper>
                <OtmLogoLink href="https://www.binance.org/en" target="_blank">
                    <span>Powered by</span>
                <img src={OtmLogo} alt="Binance Logo" /> 
                </OtmLogoLink>
                <TextFooter>Free License</TextFooter>
            </FooterWrapper> */}
        </FooterFrame>

    )
}

export default Footer