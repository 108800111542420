// import { BigNumber } from '@ethersproject/bignumber'
// import { Token/*, TokenAmount*/ } from '@bscswap/sdk'
import { Token, TokenAmount } from '@bscswap/sdk'

import { useRouterContract, useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useRouterFactory(router?: string): string | undefined {
    const contract = useRouterContract(router, false)
    const factory = useSingleCallResult(contract, 'factory')?.result?.[0]
    return router && factory ? factory : undefined
}
