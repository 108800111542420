import React, { PureComponent, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
// import Web3Status from '../Web3Status'
import { useWalletModalToggle } from '../../state/application/hooks'
import styled, { css, ThemeContext } from 'styled-components'
import IFOCounter from '../IFOCounter'
import { Collapse } from 'react-collapse'
import ToolTip from '../ToolTip'
import bnbICON from '../../assets/images/bnbICON.png'
import ethICON from '../../assets/images/ethICON.png'
import defaultImage from '../../assets/images/default.png'
import CoinICO from '../../assets/images/coinIco-04.png'
import CloseIMG from '../../assets/images/close-btn2.png'
import LShield from '../../assets/images/shildMark.png'
import RShield from '../../assets/images/qrIcon.png'
import ReactTooltip from 'react-tooltip';
import SimpleSlider from '../SimpleSlider'
import Media from '../../theme/media-breackpoint'
import { convertToPercantage } from '../../utils/numberConveter'
import { signMessage } from '../../utils/web3React'



interface KycProps {
    tokenSymbol?: string
    isOpen: boolean
    callBack: Function,
    pdoAddress: string
}


export function KycConfimation({ tokenSymbol, isOpen, callBack, pdoAddress }: KycProps) {

    const { account, library } = useActiveWeb3React()

    const [confrimMessage, setConfirmMessage] = useState('');

    const handleTextChange = (event) => {
        setConfirmMessage(event.target.value)
    }

    const signHere = async () => {

        if (confrimMessage.toLowerCase() === 'confirm') {
            localStorage.setItem(pdoAddress, '1');
            callBack(false);

        }
        // const voteMsg = JSON.stringify({
        //     type: ['KYC_CONFIRMATION'],
        //     payload: {
        //         pdoAddress:pdoAddress,
        //         user:account,
        //     }
        // })
        // const sig = await signMessage(library, account, voteMsg);


        // const message = { address: account, msg: voteMsg, sig }
        // console.log(sig)
    }

    return (
        <PPMBX style={{ display: `${isOpen ? 'flex' : 'none'}` }}>
            <PPContainer>
                <PPTitle01 style={{ color: '#4A4F57' }}><i className="fas fa-info-circle"></i>&nbsp; IMPORTANT</PPTitle01>
                <PPText01>
                    {/* old text commented*/}
                    {/* If you process the claiming now, we'll use your pending funds to buy for you {tokenSymbol.toUpperCase()} at the current market price which is <span className='greeNC'>${currentPrice}</span>, but you will wait longer your secured APY will drop your average price to <span className='greeNC'>$ {purchasePrice} </span> */}
                    All your <span style={{ color: '#00D326' }}>{tokenSymbol.toUpperCase()}</span> tokens will be LOCKED FOREVER with NO ability to send, sell, swap, or claim until you successfully complete your KYC/AML. If you are from a regulated country like the USA, you will also need to complete accreditation compliance with Regulation D 506(c) otherwise you will be unable to engage with your <span style={{ color: '#00D326' }}>{tokenSymbol.toUpperCase()}</span> tokens. You can avoid that risk by clicking <a href={`https://www.jointer.io/email-verification/${account}`} target="_blank" style={{ color: '#0096FF' }}>here</a> to start your KYC/AML before investing, otherwise type the word <span style={{ color: '#00D326' }} >CONFIRM</span> to show that you understand and agree to the above terms.
                </PPText01>
                <PPBtnMBX>
                    <PPInput type={"text"} placeholder={'Type CONFIRM here'} value={confrimMessage} onChange={handleTextChange}></PPInput>
                    <PPBtnM onClick={() => signHere()} className="greenBack">Ok</PPBtnM>
                </PPBtnMBX>
                <ClosBTN02 onClick={() => callBack(false)}><i></i></ClosBTN02>
            </PPContainer>
        </PPMBX>

    )
}


const FlexDiv = styled.div`
                display: flex; align-items:center; justify-content:center; flex-wrap: wrap; width: 100%; 
                .md-checkbox{ margin-top:0px;}
                `
const ClosBTN02 = styled.button`
                    display:flex; align-items:center; justify-content:center;width:50px; height:50px;  position:absolute; right:0; top:0; border:none; outline: none; background-color:#000; border-radius:0 0 0 10px;  transition: 400ms ease-in-out;
                        i{background: url(${CloseIMG}) 50% 50% no-repeat; width:22px; height:22px;  transition: 400ms ease-in-out;}
                        &:hover i{ transform:rotate(180deg);}
                `
const PPFrmMBX = styled(FlexDiv)`
    flex-wrap: nowrap; max-width:760px; margin:50px auto 0 auto; 
    ${Media.xs}{ flex-wrap:wrap;}
`
const PPFrmBX01 = styled(FlexDiv)`
   justify-content:flex-start; width:35%; font-size:21px; font-weight: 700; color:white;

   ${Media.sm}{ flex-direction: column; align-items:flex-start;}
   ${Media.xs}{ width:calc(100% - 15px); margin-bottom:10px; }



`
const PPFrmBX02 = styled(FlexDiv)`
   justify-content:flex-start; width:65%;
   ${Media.xs}{ width:calc(100% - 15px); margin-bottom:10px; }
`
const PPFrmInptBX = styled(FlexDiv)`
    justify-content:flex-start; border:2px solid #545861; border-radius:10px; padding:10px 90px 10px 85px; height:61px; position:relative;
    ${Media.xs}{ padding:10px 80px 10px 42px;   }

    input{ width:100%; height:40px; background-color:transparent; border:none; outline:none; color:white; font-size:24px; font-weight:700;}
    i.leftShild{ position:absolute; left:0; top:0; bottom:0; background-color:#545861; width:66px; border-radius:9px 0 0 9px; display: flex; align-items:center; justify-content:center;
        ${Media.xs}{ width:36px;  }
    }
    i.rightShild{ position:absolute; right:0; top:0; bottom:0;  width:90px; border-radius:9px 0 0 9px; display: flex; align-items:flex-end; justify-content:center; flex-direction:column; font-style:normal; font-size:11px; color:#8e9195; line-height:20px; padding-right:10px;
        img {margin-bottom:2px;}
     }
`
const PPMBX = styled(FlexDiv)`
    position:fixed; width:100%; left:0; top:0; right:0; bottom:0; z-index:101; background-color: rgba(0, 0, 0, 0.5);
    ${Media.sm}{ overflow-y:auto; top:auto; }
`
const PPContainer = styled(FlexDiv)` 
        max-width:980px; margin:0 auto; padding:44px; background-color:#16191e; border:2px solid #000000; border-radius:10px; position:relative; 
        ${Media.xs}{ padding:44px 20px; }
`
const PPTitle01 = styled(FlexDiv)`
    font:700 36px/42px 'Kanit', arial; text-align:center; color: #fff; margin:40px 0 15px 0;
`
const PPTitle02 = styled(FlexDiv)`
    font:700 36px/42px 'Kanit', arial; text-align:center; color: #00f02b; margin:40px 0 15px 0; 
    .fas { margin-right:8px;}
`
const PPTitle03 = styled(PPTitle02)`
    color: #c32b2d;
`

const PPTitle04 = styled(PPTitle02)`
    color: #00ed2a; flex-direction: column;  margin:20px 0 0 0;
    span{ font:400 12px/30px 'Kanit', arial;}
`

const PPText01 = styled.div`
    font-size:18px; line-height:30px; font-weight:400; text-align:center; color: #fff; width:100%;
    & span.greeNC{ color:#00f02b; display:inline;}
`
const PPBtnMBX = styled(FlexDiv)`
    justify-content:space-between; margin:60px 0 0 0;
`

const PPInput = styled.input`
    border:none; outline: none; border-radius:10px; padding:20px; background-color:#5d6168;  font:700 24px/30px 'Kanit', arial; text-align:center; width:calc(50% - 30px); color:#fff; margin:0 15px;
    :hover{ background-color:#4a4f57;}
    &.redBack{ background-color:#c32b2d;  :hover{ background-color:#a91719; }}
    &.greenBack{ background-color:#00f02b; color:#5d6168;  :hover{ background-color:#00d326; } }
    &.fullW{ width:calc(100% - 30px);}

    ${Media.sm}{ width:calc(100% - 15px); margin-bottom:10px; }


`

const PPBtnM = styled.button`
    border:none; outline: none; border-radius:10px; padding:20px; background-color:#5d6168;  font:700 24px/30px 'Kanit', arial; text-align:center; width:calc(50% - 30px); color:#fff; margin:0 15px;
    :hover{ background-color:#4a4f57;}
    &.redBack{ background-color:#c32b2d;  :hover{ background-color:#a91719; }}
    &.greenBack{ background-color:#00f02b; color:#5d6168;  :hover{ background-color:#00d326; } }
    &.fullW{ width:calc(100% - 30px);}

    ${Media.sm}{ width:calc(100% - 15px); margin-bottom:10px; }


`
const PPSliderMBX = styled.div`
    width:100%; max-width: 824px; position: relative; margin:0 auto; 
`
const Input = styled.input`
    background: none!important;
    border: none!important;
    appearance: none;
    border-radius: 4px;
    display:none;
`
const Label = styled.label`
    color:#8e9195;
    font-size: 14px !important;
    font-weight: 400 ; 
    margin-right: 0 !important;
    margin-left: 30px;
    &:before {
        border: 2px solid rgba(0, 0, 0, 1) !important;
         border-radius: 4px !important; 
        background: rgba(255, 255, 255, 0.05) !important;
        right: auto !important; top: 6px !important;
        left: 0; 
    }
    &:after { 
        transform: rotate(-45deg);
        top: 6px !important;
        right: auto !important;
        width: 18px;
        height: 9px;
        border: 3px solid #00f02b;
        border-top-style: none;
        border-right-style: none;
        left: -4px; 
    }
`