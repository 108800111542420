import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Media from '../../theme/media-breackpoint'
import Modal from '../Modal'

interface TermsAndConditionsProps {
  message: string
  isOpen: boolean
  setLicenseAccepted: Function
}
export default function TermsAndConditions({ message, isOpen, setLicenseAccepted }: TermsAndConditionsProps) {
  const [isChecked, setIsChecked] = useState(false)
  const [open, setIsOpen] = useState(isOpen)

  //Handle Checkbox
  const handleCheckBox = () => {
    setIsChecked(prevState => !prevState)
  }

  //Handle Submit
  const handleSubmit = () => {
    setIsOpen(false)
    setLicenseAccepted(true)
  }

  return (
    <Modal isOpen={open} onDismiss={() => {}} maxHeight={90}>
      <PPMBX>
        <PPContainer>
          <TermsContainer>
            <PPTitle01>Term and Conditions of licensing use, Intellectual Property Rights and Non-Compete</PPTitle01>
            <PPTitle03>
              The following terms and Conditions of licensing use, Intellectual Property Rights and <br></br>
              Non-Compete (the “Terms”) constitute an agreement between you or the company or other legal entity you
              represent (“you” or “your”) and Qonetum Finance (“Qonetum Finance,” “we,” or “us” “the company”), a
              company incorporated in Delaware USA and You agree that you have read, understood, and accepted these
              Terms as well as our updates from time to time, and you acknowledge and agree that you will be bound by
              those terms.
            </PPTitle03>
            <TermsContent>
              <PPTitle03 className="text-center">
                <u>RECITALS</u>
              </PPTitle03>

              <p>
                The followingTerms and Conditions govern the use of the Service and the agreement that operates between
                You and the Company. These Terms and Conditions set out the rights and obligations of all users
                regarding the use of the Service.
              </p>
              <p>
                Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
                Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or
                use the Service.
              </p>
              <p>
                By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree
                with any part of these Terms and Conditions then You may not access the Service.
              </p>
              <p>
                GRANT & USE: Company hereby grants to the Licensee, and the Licensee accepts from the Licensor, a
                non-exclusive, non-transferable license to use the Licensed Products and Enhancements, on the terms and
                conditions set forth in this Agreement. The license to use the Licensed Product is in force from the
                Effective Date of this Agreement. The Licensee contracts for, and the Company agrees to provide, the
                Licensed Product on the terms and conditions set forth in this Agreement.
              </p>
              <p>
                ELIGIBILITY. If you are an individual, you must be of legal age in the jurisdiction in which you reside
                and you have the legal capacity to enter into these Terms. If you are on behalf of a legal entity, you
                represent and warrant that (i) such legal entity is duly organized and validly existing under the
                applicable laws of the jurisdiction of its organization; (ii) you are duly authorized by such legal
                entity to act on its behalf.
              </p>
              <p>
                By accessing or using Company's products and/or services, you further represent and warrant that you are
                not a Restricted Person nor are you a resident of any Restricted Territory and you will not be using
                Company's products and/or services for any illegal activity including, but not limited to, those
                Restricted Activities listed under this Agreement. Notwithstanding the foregoing, we may determine not
                to make the services, in whole or in part, available in every market, either in its sole discretion or
                due to legal or regulatory requirements, depending on your location. Additionally, use of a virtual
                private network (e.g., a VPN) or other means by Restricted Persons or persons from Restricted
                Territories to access or use our service is prohibited.
              </p>
              <p>
                RESTRICTED ACTIVITIES. In connection with your use of our services, you will not: (i) violate or assist
                any party in violating any law, statute, ordinance, regulation or any rule of any self-regulatory or
                similar organization of which you are or are required to bea member through your use of our
                services;(ii) provide false, inaccurate, incomplete or misleading information;(iii) infringe upon
                Company's or any third party's copyright, patent, trademark, or intellectual property rights;(iv) engage
                in any illegal activity, including without limitation illegal gambling, money laundering, fraud,
                blackmail, extortion, ransoming data, the financing of terrorism, other violent activities or any
                prohibited market practices;(v) distribute unsolicited or unauthorized advertising or promotional
                material, written media releases, public announcements and public disclosures, junk mail, spam or chain
                letters;(vi) take any action that imposes an unreasonable or disproportionately large load on our
                infrastructure, or detrimentally interfere with, intercept, or expropriate any system, data or
                information;(vii) transmit or upload any material to Company's products and/or services that contains
                viruses, Trojan horses, worms, or any other harmful or deleterious programs; (viii) transfer any rights
                granted to you under these Terms;(ix) engage in any other activity which, in our reasonable opinion,
                amounts to or may amount to market abuse including without limitation the carrying out of fictitious
                transactions or wash trades, front running or engaging in disorderly market conduct; or(x) engage in any
                behavior which is unlawful, violates these Terms, or is otherwise deemed unacceptable by Company in its
                sole discretion.
              </p>

              <ol type="1">
                <li>
                  <strong>Definitions:</strong>
                  As used herein, “<b>Confidential Information</b>” means any and all technical and non-technical
                  information provided by the Company to the Recipient, which may include without limitation information
                  regarding: (a) patent and patent applications; (b) trade secrets; (c) blockchain technology
                  implementations, cryptography, digital assets, blockchain based software systems, tokens and
                  tokenization, smart contracts; digital trading platforms; digital trading strategies; digital assets;
                  Inventions and (d) proprietary and confidential information, ideas, techniques, sketches, drawings,
                  works of authorship, models, inventions, know-how, processes, apparatuses, equipment, algorithms,
                  software programs, software source documents, source code, business plans and formulae related to the
                  current, future, and proposed products and services of the Company, including without limitation the
                  Company's information concerning research, experimental work, development, design details and
                  specifications, engineering, financial information, procurement requirements, purchasing,
                  manufacturing, customer lists, investors, employees, business and contractual relationships, business
                  forecasts, sales and merchandising, marketing plans and information the Company provides regarding
                  third parties; and (d) all other information that the Recipient knew, or reasonably should have known,
                  was the Confidential Information of the Company. As used herein “Invention” means each Licensee agrees
                  that if the other Licensee breaches, or proposes to breach, any portion of this Agreement, the other
                  Party shall be entitled, in addition to all other remedies that it may have, to expedited relief,
                  including a temporary restraining order or a permanent injunction, or other appropriate equitable
                  relief to restrain any such breach without showing or proving any actual damage to the other Licensee.
                  The other Licensee shall not be required, as a condition to seeking or obtaining any injunctive or
                  other equitable relief, to post or otherwise provide any bond, surety or other assurance or security,
                  and the Parties hereby expressly waive any such requirement. Any award or relief to a Licensee shall
                  include such Licensee's cost and expenses of enforcement (including, without limitation, reasonable
                  attorneys' and other experts' fees, court costs and expenses). Each Licensee has carefully considered
                  the nature and extent of the restrictions upon it hereunder and the rights and remedies conferred upon
                  each Licensee under this Agreement, and hereby acknowledges and agrees that the same are reasonable in
                  all respects, are designed to protect the legitimate business interests of each Licensee and do not
                  confer benefits upon any Licensee disproportionate to the detriment of the other Licensee. Nothing
                  contained in this Section 8 shall restrict or limit in any manner each Licensee's right to seek and
                  obtain any form of relief, legal or equitable, in an action brought to enforce its rights hereunder
                </li>
                <li>
                  Subject to Section 3, the Recipient agrees that at all times and notwithstanding any termination or
                  expiration of this Agreement it will hold in strict confidence and not disclose to any third party any
                  Confidential Information, except as approved in writing by the Company, and will use the Confidential
                  Information for no purpose other than the Permitted Use. The Recipient will also protect such
                  Confidential Information with at least the same degree of care that the Recipient uses to protect its
                  own Confidential Information, but in no case, less than reasonable care. The Recipient will limit
                  access to the Confidential Information to only those of its employees or authorized representatives
                  having a need to know and who have signed confidentiality agreements containing, or are otherwise
                  bound by, confidentiality obligations at least as restrictive as those contained herein.
                  <ol type="1">
                    <li>
                      Subject to Section 3, the Recipient agrees that at all times and notwithstanding any termination
                      or expiration of this Agreement it will hold in strict confidence and not disclose to any third
                      party any Confidential Information, except as approved in writing by the Company, and will use the
                      Confidential Information for no purpose other than the Permitted Use. The Recipient will also
                      protect such Confidential Information with at least the same degree of care that the Recipient
                      uses to protect its own Confidential Information, but in no case, less than reasonable care. The
                      Recipient will limit access to the Confidential Information to only those of its employees or
                      authorized representatives having a need to know and who have signed confidentiality agreements
                      containing, or are otherwise bound by, confidentiality obligations at least as restrictive as
                      those contained herein.
                    </li>
                    <li>
                      Export Compliance. Confidential Information exchanged under this Agreement may be subject to U.S.
                      export control laws and regulations, such as the Export Administration Regulations and the
                      International Traffic in Arms Regulations. The receiving party shall not export, disclose,
                      furnish, transfer, or otherwise provide any Confidential Information, including but not limited to
                      any article, technical data, technology, defense service or technical assistance of the other
                      party, to any foreign person or entity whether located within the United States or abroad, without
                      obtaining in advance the appropriate U.S. government export authorization. The recipient of
                      Confidential Information under this Agreement acknowledges its obligations to control access to
                      technical data in accordance with U.S. export laws and regulations. Each party agrees that it will
                      not, in any form, export, re-export, re-sell, ship, divert or cause to be exported, directly or
                      indirectly, any technical data or products subject to U.S. export regulations, to any country for
                      which the U.S. Government or any agency thereof at the time of export requires an export license
                      or other government approval, without first obtaining authorization.
                    </li>
                  </ol>
                </li>
                <li>
                  The Recipient will not have any obligations under this Agreement with respect to a specific portion of
                  the Confidential Information if the Recipient can demonstrate with competent evidence that such
                  Confidential Information:
                  <ol type="a">
                    <li>was in the public domain at the time it was disclosed to the Recipient;</li>
                    <li>
                      entered the public domain subsequent to the time it was disclosed to the Recipient, through no
                      fault of the Recipient;
                    </li>
                    <li>
                      was in the Recipient's possession free of any obligation of confidence at the time it was
                      disclosed to the Recipient
                    </li>
                    <li>
                      was rightfully communicated to the Recipient free of any obligation of confidence subsequent to
                      the time it was disclosed to the Recipient; or
                    </li>
                    <li>
                      was developed by employees or agents of the Recipient who had no access to any Confidential
                      Information.
                    </li>
                  </ol>
                </li>
                <li>
                  Notwithstanding the above, the Recipient may disclose certain Confidential Information, without
                  violating the obligations of this Agreement, to the extent such disclosure is required by a valid
                  order of a court or other governmental body having jurisdiction, provided that the Recipient provides
                  the Company with reasonable prior written notice of such disclosure and makes a reasonable effort to
                  obtain, or to assist the Company in obtaining, a protective order preventing or limiting the
                  disclosure and/or requiring that the Confidential Information so disclosed be used only for the
                  purposes for which the law or regulation required, or for which the order was issued.
                </li>
                <li>
                  The Recipient will immediately notify the Company in the event of any loss or unauthorized disclosure
                  of any Confidential Information.
                </li>
                <li>
                  Upon termination or expiration of this Agreement, or upon written request of the Company, the
                  Recipient will promptly return to the Company all documents and other tangible materials representing
                  any Confidential Information and all copies thereof.
                </li>
                <li>
                  Confidential Information is and shall remain the sole property of the Company. The Recipient
                  recognizes and agrees that nothing contained in this Agreement will be construed as granting any
                  property rights, by license or otherwise, to any Confidential Information disclosed under this
                  Agreement, or to any Invention or any patent, copyright, trademark, or other intellectual property
                  right that has issued or that may issue, based on such Confidential Information. The Recipient will
                  not make, have made, use or sell for any purpose any product or other item using, incorporating or
                  derived from any Confidential Information. Neither this Agreement nor the disclosure of any
                  Confidential Information hereunder shall result in any obligation on the part of either party to enter
                  into any further agreement with the other, license any products or services to the other, or to
                  require the Company to disclose any particular Confidential Information. Nothing in this Agreement
                  creates or shall be deemed to create any employment, joint venture, or agency between the parties.
                </li>
                <li>
                  Confidential Information will not be reproduced in any form except as required to accomplish the
                  intent of this Agreement. Any reproduction of any Confidential Information will remain the property of
                  the Company and will contain any and all confidential or proprietary notices or legends that appear on
                  the original, unless otherwise authorized in writing by the Company.
                  <ol type="1">
                    <li>
                      Any materials or documents that have been furnished by one party to the other in connection with
                      the Relationship or Confidential Information shall be promptly returned or destroyed by the
                      receiving party (at the receiving party's option), upon the written request of the disclosing
                      party. If requested by the disclosing party, the receiving party will certify that all
                      Confidential Information has been returned or destroyed. Notwithstanding the foregoing, the
                      receiving party will not be obligated to return or destroy any Confidential Information that is
                      contained in an archived computer system backup in accordance with its record keeping policies,
                      security and/or disaster recovery procedures, and it may retain one copy of all Confidential
                      Information for archival or record retention purposes as required by applicable law, statute or
                      regulation. All Confidential Information shall continue to be governed by the provisions hereof
                      following any such return or destruction of documentary materials.
                    </li>
                  </ol>
                </li>
                <li>
                  Except as otherwise explicitly permitted by this Agreement, the Recipient covenants and agrees that at
                  all times during the period beginning on the date of this Agreement and ending on the date that is two
                  (2) years from the date of this Agreement, the Recipient will not, directly or indirectly, carry on,
                  engage in, participate in, assist, or become financially interested in a Competing Business.
                  “Competing Business” means any person, company, or entity whose primary business at the time is, or
                  whose then-current business plan contemplates engaging in technology, analytical, administrative or
                  support services, products or activities which may be, competitive with services that were or were
                  being designed, conceived, marketed, and/or developed by the Company, including but not limited to any
                  protocols relevant to Qonetum Finance , or any other activities so similar in nature or purpose to
                  those offered by or engaged in by any entity within the Company that they would displace business
                  opportunities or customers of such entity within the Company. This restriction is limited to the
                  United States and any other country in which any entity within the Company and Recipient have
                  operations at the time of termination (the “Territory”), which the Parties stipulate is a reasonable
                  geographic area because of the scope of the operations of the Company and Recipient. Recipient agrees
                  and acknowledges that Company has a valid and legitimate business interest in protecting its Business,
                  Confidential Information, and goodwill, in the Territory from any activity prohibited by this
                  Agreement. Recipient further agrees and acknowledges that this Section 9 creates a narrowly tailored
                  advance approval requirement in order to avoid unfair competition and irreparable harm to the entities
                  within the Company and is not intended or to be construed as a general restraint from engaging in a
                  lawful trade or a general covenant against competition.
                </li>
                <li>
                  This Agreement will terminate five (5) year(s) after the Effective Date or may be terminated by either
                  party at any time upon thirty (30) days written notice to the other party. The Recipient's obligations
                  under this Agreement will survive termination of this Agreement and will be binding upon the
                  Recipient's heirs, successors, and assigns. The Recipient's obligations with respect to all
                  Confidential Information will terminate only pursuant to Section 3.
                </li>
                <li>
                  The Company is providing Confidential Information on an “AS IS” basis for use by the recipient at its
                  own risk. The Company disclaims all WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING
                  WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD- PARTY RIGHTS,
                  MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. ONLY THOSE REPRESENTATIONS OR WARRANTIES THAT
                  ARE MADE TO THE OTHER IN A DEFINITIVE AGREEMENT WHEN, AS, AND IF, EXECUTED, AND SUBJECT TO SUCH
                  LIMITAITONS AND RESTRICTIONS AS MAY BE SPECIFIED IN SUCH AGREEMENT, IN WRITING, WILL HAVE ANY LEGAL
                  EFFECT Without limiting the foregoing, neither the Company nor any of the company's provider makes any
                  representation or warranty of any kind, express or implied: (i) as to the operation or availability of
                  the Service, or the information, content, and materials or products included thereon; (ii) that the
                  Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any
                  information or content provided through the Service; or (iv) that the Service, its servers, the
                  content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses,
                  worms, malware, timebombs or other harmful components. Some jurisdictions do not allow the exclusion
                  of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or
                  all of the above exclusions and limitations may not apply to You. But in such a case the exclusions
                  and limitations set forth in this section shall be applied to the greatest extent enforceable under
                  applicable law.
                </li>

                <li>
                  This Agreement and any action related thereto will be governed, controlled, interpreted, and defined
                  by and under the laws of the State of Texas, without giving effect to any conflicts of laws principles
                  that require the application of the law of a different state. Any disputes under this Agreement may be
                  brought in the Texas state and Federal courts in Harris County, and the parties hereby consent to the
                  personal jurisdiction and exclusive venue of these courts[A2] . This Agreement may not be amended
                  except by a writing signed by both parties. Each of the parties hereby irrevocably waives, to the
                  fullest extent permitted by applicable law, any and all right to trial by jury in any legal proceeding
                  arising out of or relating to this Agreement or the transactions contemplated hereby.
                </li>

                <li>
                  The Recipient hereby agrees that its breach of this Agreement will cause irreparable damage to the
                  Company for which recovery of damages would be inadequate, and that the Company will be entitled to
                  obtain timely injunctive relief under this Agreement, as well as such further relief as may be granted
                  by a court of competent jurisdiction. Each Party agrees that if the other Party breaches, or proposes
                  to breach, any portion of this Agreement, the other Party shall be entitled, in addition to all other
                  remedies that it may have, to expedited relief, including a temporary restraining order or a permanent
                  injunction, or other appropriate equitable relief to restrain any such breach without showing or
                  proving any actual damage to the other Party. The other Party shall not be required, as a condition to
                  seeking or obtaining any injunctive or other equitable relief, to post or otherwise provide any bond,
                  surety or other assurance or security, and the Parties hereby expressly waive any such requirement.
                  Any award or relief to a Party shall include such Party's cost and expenses of enforcement (including,
                  without limitation, reasonable attorneys' and other experts' fees, court costs and expenses). Each
                  Party has carefully considered the nature and extent of the restrictions upon it hereunder and the
                  rights and remedies conferred upon each Party under this Agreement, and hereby acknowledges and agrees
                  that the same are reasonable in all respects, are designed to protect the legitimate business
                  interests of each Party and do not confer benefits upon any Party disproportionate to the detriment of
                  the other Party. Nothing contained in this Section 12 shall restrict or limit in any manner each
                  Party's right to seek and obtain any form of relief, legal or equitable, in an action brought to
                  enforce its rights hereunder
                </li>

                <li>
                  THE RECIPIENT AGREES TO INDEMNIFY THE COMPANY AND HOLD THEM HARMLESS FROM ANY LIABILITY, LOSS, COST OR
                  EXPENSE, INCLUDING REASONABLE ATTORNEY'S FEES AND EXPENSES THAT SHALL RESULT FROM OR ARISE OUT OF ANY
                  BREACH OF THIS AGREEMENT BY THE RECIPIENT, AND ANY OF THE RECEIPIENT'S EMPLOYEES OR REPRESENTATIVES.
                </li>
                <li>
                  Recipient shall not, without the prior written consent of the Company, disclose to any other person
                  the fact that Confidential Information of the Company [has been and/or] may be disclosed under this
                  Agreement, that discussions or negotiations are taking place between the Company and Recipient, or any
                  of the terms, conditions, status or other facts with respect thereto, except as required by law and
                  then only with prior notice as soon as possible to the Owner.
                </li>
                <li>
                  If any provision of this Agreement is found by a proper authority to be unenforceable or invalid, such
                  unenforceability or invalidity will not render this Agreement unenforceable or invalid as a whole and,
                  in such event, such provision will be changed and interpreted so as to best accomplish the objectives
                  of such unenforceable or invalid provision within the limits of applicable law or applicable court
                  decisions. Any waiver or failure to enforce any provision of this Agreement on one occasion will not
                  be deemed a waiver of any other provision or of such provision on any other occasion.
                </li>
                <li>
                  The Recipient will not assign or transfer any rights or obligations under this Agreement without the
                  prior written consent of the Company and any attempted assignment, subcontract, delegation, or
                  transfer in violation of the foregoing will be null and void.
                </li>
                <li>
                  The Recipient will not export, directly or indirectly, any U.S. technical data acquired pursuant to
                  this Agreement, or any products utilizing such data, in violation of the United States export laws or
                  regulations.
                </li>
                <li>
                  All notices or reports permitted or required under this Agreement will be in writing and will be
                  delivered by personal delivery, electronic mail, facsimile transmission or by certified or registered
                  mail, return receipt requested, and will be deemed given upon personal delivery, five (5) days after
                  deposit in the mail, or upon acknowledgment of receipt of electronic transmission. Notices will be
                  sent to the addresses set forth at the end of this Agreement or such other address as either party may
                  specify in writing.
                </li>
                <li>
                  The Recipient agrees that the software programs, Inventions, and Confidential Information, of the
                  Company contain valuable information and agrees that it will not modify, reverse engineer, decompile,
                  create other works from, disassemble, use or distribute any software programs, Inventions, and
                  Confidential Information without the prior written consent of the Company.
                </li>
                <li>
                  The Recipient and Company may enter into similar agreement with other parties, provided that the
                  Recipient and Company are in compliance with this Agreement, including, but not limited to, Section 9.
                </li>
                <li>
                  This Agreement is the final, complete and exclusive agreement of the parties with respect to the
                  subject matters hereof and supersedes and merges all prior discussions between the parties with
                  respect to such matters. No modification of or amendment to this Agreement will be effective unless in
                  writing and signed by the party to be charged.
                </li>
                <li>
                  <strong>Intellectual Property Rights:</strong>
                  All files, records, documents, drawings, specifications, equipment, notebooks, notes, memoranda,
                  diagrams, blueprints, bulletins, formula, reports, analyses, computer programs, and any other data of
                  any kind relating to the Company, whether prepared by me or anyone on my behalf or otherwise coming
                  into my possession, and whether classified as Confidential Information or not, shall remain the
                  Company's exclusive property. I hereby acknowledge and agree that all inventions, developments,
                  improvements, mask works, trade secrets, copyrights, modifications, discoveries, concepts, ideas,
                  techniques, methods, technologies, knowhow, designs, data, processes, proprietary information, whether
                  or not patentable or otherwise protectable, and all intellectual property rights associated therewith,
                  which are (or have been) invented, made, developed, discovered, conceived or created, at any time, in
                  whole or in part, by me (or anyone on my behalf, independently, or jointly with others, (i) as a
                  result of and/or within the framework of providing services to the Company; and/or (ii) with the use
                  of any Company equipment, supplies, facilities, or proprietary information (all of the above: the
                  “Company IP Rights”), are and shall be the sole and exclusive property of the Company. I and/or anyone
                  on my behalf, shall have no rights, claims or interest whatsoever in or with respect to the Company IP
                  Rights. For the removal of any doubt, I shall promptly disclose in confidence to the Company any and
                  all Company IP Rights, and I hereby irrevocably and unconditionally assign to the Company any and all
                  rights and interests in the Company IP Rights. I hereby further agree and undertake that if and to the
                  extent any additional action is required by me in order to perfect, enforce, or defend said Company IP
                  Rights, as described above, and effectuate the Company's title and interest therein, including to
                  effect the formal transfer thereof to the Company - I shall take all necessary measures, fully
                  cooperate and perform any such action immediately upon the Company's request. I further represent and
                  warrant to the Company that no obligation I have to any third party will result in any third party
                  having any rights in any Company IP Rights. I hereby irrevocably designate and appoint the Company and
                  its authorized officers and agents as my agent and attorney in fact, coupled with an interest to act
                  for and on my behalf and in my stead to execute and file any document needed to apply for or prosecute
                  any patent, copyright, trademark, trade secret, any applications regarding same or any other right or
                  protection relating to any Company IP Rights, and to do all other lawfully permitted acts to further
                  the prosecution and issuance of patents, copyrights, trademarks, trade secrets or any other right or
                  protection relating to any Company IP Rights, with the same legal force and effect as if executed by
                  myself.
                </li>
                <li>
                  <strong>Non-Compete:</strong>
                  During the period of this engagement with Company and for a period of 24 months thereafter,I will not
                  engage in, directly or indirectly, become financially interested in, be employed by or consult with,
                  or have any connection with any business or venture that is a competitor of the Company. Additionally,
                  I will not solicit, contact, call upon, or attempt to communicate with any customer or prospective
                  customer, or investor or potential investor of the Company for the purpose of providing any products
                  or services substantially similar to those Company provided by the Company. This restriction shall
                  apply to any customer or prospective customer, or investor or potential investor of the Company with
                  whom Purchaser had contact or about whom I learned Trade Secrets, Confidential Information, or Third
                  Party Information during the last twenty-four (24) month. For the purpose of this Section, “contact”
                  means interaction between the company and the customer, or prospective customer which takes place to
                  further the business relationship, or making sales to or performing services for the customer, or
                  prospective customer on behalf of the Company. Furthermore I will not recruit, hire, or attempt to
                  recruit or hire, directly or by assisting others, any employee of the Company with whom I had contact
                  or about whom I learned Trade Secrets, Confidential Information, or Third Party Information during
                  Purchaser's last twenty-four (24) months. For the purposes of this section, “contact” means any
                  interaction whatsoever between Purchaser and the other employee. “Competitor” being identified as any
                  business or business activities related to any Qonetum Fiannce protocols.
                </li>
                <li>
                  <strong>Relationship Not Exclusive:</strong>
                  The parties acknowledge and agree that either party may enter into similar services arrangements with
                  other firms, consultants and clients, provided that I will comply with the foregoing non-compete
                  provision. I further represent and warrant to the Company that neither this Agreement nor the
                  performance thereof by me, including without limitation my obligations under “Confidentiality” and
                  “Intellectual Property Rights”, will conflict with or violate any obligation of Advisor or right of
                  any third party.
                </li>
              </ol>
            </TermsContent>
          </TermsContainer>
          <PPTitle02 onClick={handleCheckBox}>
            <i className={isChecked ? 'far fa-check-square' : 'far fa-square'}></i>
            {message}
          </PPTitle02>
          {isChecked ? <BinanceTitle01 onClick={handleSubmit}>OK</BinanceTitle01> : <DisableButton>OK</DisableButton>}
        </PPContainer>
      </PPMBX>
    </Modal>
  )
}

const TermsContainer = styled.div`
  height: 80vh;
  overflow-y: scroll;
`

const BinanceTitle01 = styled.div`
  background-color: inherit;
  display: block;
  text-align: center;
  padding: 17px 8px;
  border-radius: 10px;
  font: bold 18px/28px 'IBM Plex Mono', arial, sans-serif;
  margin: 28px 0px 20px;
  border: 2px solid #00f02b;
  box-shadow: 0 0 8px #00f02b;
  color: #fff;
  min-width: 200px;
  :hover {
    background-color: #00f02b;
    cursor:pointer;
  }
  text-transform: uppercase;
`

const DisableButton = styled.button`
  display: block;
  text-align: center;
  padding: 17px 8px;
  border-radius: 10px;
  font: bold 18px/28px 'IBM Plex Mono', arial, sans-serif;
  margin: 28px 0px 20px;
  border: 2px solid #5d6168;
  box-shadow: 0 0 8px #5d6168;
  background-color: inherit;
  color: #5d6168;
  min-width: 200px;
  pointer-events:none;
`

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .md-checkbox {
    margin-top: 0px;
  }
`
const PPMBX = styled(FlexDiv)`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  ${Media.sm} {
    overflow-y: auto;
    top: auto;
  }
`
const PPContainer = styled(FlexDiv)`
  max-width: 1000px;
  margin: 0 auto;
  padding: 44px;
  background-color: #16191e;
  border: 2px solid #000000;
  border-radius: 10px;
  position: relative;
  max-height: calc(100% - 100px);
  overflow: auto;
  justify-content: flex-start;
  ${Media.xs} {
    padding: 44px 20px;
  }
`
const PPTitle01 = styled(FlexDiv)`
  font: 700 36px/42px 'Kanit', arial;
  text-align: center;
  color: #fff;
  margin: 40px 0 15px 0;
`
const PPTitle02 = styled(FlexDiv)`
  font: 700 20px/12px 'Kanit', arial;
  text-align: center;
  color: #00f02b;
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 40px 0 15px 0;
  .far {
    margin-right: 8px;
  }
  cursor: pointer;
`
const PPTitle03 = styled.span`
  font: 500 23px/1.4 'Kanit', arial;
  color: #fff;
  margin: 40px 0 15px 0;
  display: block;
  &.text-center {
    text-align: center;
  }
`
const TermsContent = styled.div`
  strong {
    display: block;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  ol {
    font-size: 18px;
    line-height: 1.4;
    li {
      margin-bottom: 20px;
    }
    ol {
      list-style-type: decimal-leading-zero;
      &[type='a'] {
        list-style-type: lower-latin;
      }
      li {
      }
    }
  }
  /*  ol[type="1"] {
    counter-reset: item;
    list-style-type: none;
    li { counter-increment: item ; 
      &:before { content: counters(item, ".") ". ";}
      ol {
        li:before {content: counters(item, ".") " "; }
        &[type=a] {
          list-style-type: lower-latin;
          :before {display: none;}
        }
      }
    }
  } */
`
