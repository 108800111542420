import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
// import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Footer from '../components/Footer'
// import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import IFOList from './IFOList'
import Home from './Home'
// import bg02 from '../assets/images/bg02.png'
import IFOLaunch from './IFOLaunch'
import { RedirectPathToListOnly/*, RedirectToHome*/ } from './IFOList/redirects'
// import { ThemeContext } from 'styled-components'
import HomeBG from '../assets/images/darkBG.png'
import pdoLogo from '../assets/images/pdo-logo.png'
import axios from 'axios'
import PDOLicence from '../components/PDOLicence'
import { API_BASEURL } from '../constants'
import { useQuery } from '../hooks/useQuery'


const AppWrapper = styled.main`
/* background-image: HomeBG; */
background: url(${HomeBG}) center top no-repeat ${({ theme }) => theme.ifoBg3};
`

const LoadingDiv = styled.div`
display: flex;
width:100%; 
min-height:100vh; 
align-items:center;
justify-content:center;
& img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
`
export default function App() {
  // const theme = useContext(ThemeContext)

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  const [isLoading, setIsLoading] = useState(true);





  const getDomainData = async (domainName: string) => {
    const res = await axios.get(`${API_BASEURL}/getDomain/${domainName}`);
    if (res.status === 200) {
      const responseData = res.data;
      if (responseData.data === null) {
        window["isLicenses"] = false;
        setIsLoading(false);
      }
      if (responseData.data !== null) {
        window["isLicenses"] = true;
        window["LicensesHash"] = responseData.data.txHash;
        window["LicensesConfig"] = responseData.data;
        const licensesRes = await axios.get(`${API_BASEURL}/getLicenses/${responseData.data.txHash}`);
        if (licensesRes.status === 200) {
          window["Licenses"] = licensesRes.data.data;
          setIsLoading(false);
          updateLayout();
        } else {
          setIsLoading(false);
        }
      }

    } else {
      setIsLoading(false);
    }

  }
  const updateLayout = () => {
    const LicensesConfig = window["LicensesConfig"]
    console.log("LicensesConfig", document.getElementById("forRmBg"))
    document.body.style.fontFamily = LicensesConfig.fontStyle;
    document.getElementById("forRmBg").classList.remove("bIeVaR");
    (document.getElementById('logoImgSd') as HTMLImageElement).src = LicensesConfig.logo;
    var style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `* { color: ${LicensesConfig.primaryColors} !important; font-family: ${LicensesConfig.fontStyle}}
                        button { background-color :${LicensesConfig.seconderyColor} !important; }
                        #forRmBg { background-image: url(${LicensesConfig.backGroundImage})!important;background-size:cover;}
                        #startSection{min-height:100vh}
                        .changeBtn { background-color :${LicensesConfig.seconderyColor} !important; }
                        #logoImgSd{ max-width: 50px;}`;
    document.getElementsByTagName('head')[0].appendChild(style);
    document.getElementsByTagName('body')[0].classList.add('subdomain');

  }

  const setPreview = async () => {
    setIsLoading(false);
    window["LicensesConfig"] = {}
    window["LicensesConfig"]["fontStyle"] = localStorage.getItem('fontStyle');
    window["LicensesConfig"]["logo"] = localStorage.getItem('logo');
    window["LicensesConfig"]["primaryColors"] = localStorage.getItem('primaryColors');
    window["LicensesConfig"]["backGroundImage"] = localStorage.getItem('backGroundImage');
    window["LicensesConfig"]["seconderyColor"] = localStorage.getItem('seconderyColor');
    setTimeout(() => {
      updateLayout();
    }, 100)

  }

  useEffect(() => {
    window["transactionPending"] = false;
    const href = window.location.href;
    const domaindata = href
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      .split('/')[0];
    const domain = domaindata.split('.');

    if (domain.length >= 2 && domain[0] !== "app") {
      getDomainData(domain[0])
    } else if (getParameterByName("preview")) {
      setPreview()
    } else {
      window["isLicenses"] = false;
      setIsLoading(false);
    }

  }, []);

  return (
    isLoading ?
      <LoadingDiv >
        <img src={pdoLogo} height={100}></img>
      </LoadingDiv>
      :
      <Suspense fallback={null}>
        <HashRouter>
          {/* <Route component={GoogleAnalyticsReporter} /> */}
          {/* <Route component={DarkModeQueryParamReader} /> */}
          <AppWrapper id="forRmBg">
            {/* <Popups /> */}
            <Header />
            <Web3ReactManager>
              <Switch>
                <Route exact strict path="/home" component={Home} />
                <Route exact strict path="/list" component={IFOList} />
                <Route exact strict path="/pdolaunch" component={IFOLaunch} />
                <Route exact strict path="/license" component={PDOLicence} />
                <Route component={RedirectPathToListOnly} />
              </Switch>
            </Web3ReactManager>
            {!window["isLicenses"] && <Footer />}
          </AppWrapper>
        </HashRouter>
      </Suspense>

  )
}
