import React from 'react'
import styled/*, { css }*/ from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { darken/*, lighten*/ } from 'polished'
import { Activity } from 'react-feather'
import useENSName from '../../hooks/useENSName'
import { useHasSocks } from '../../hooks/useSocksBalance'
import { useWalletModalToggle } from '../../state/application/hooks'
import { ACTIVE_NETWORK } from '../../constants'
import Media from '../../theme/media-breackpoint'
// import { TransactionDetails } from '../../state/transactions/reducer'

// import Identicon from '../Identicon'
// import PortisIcon from '../../assets/images/portisIcon.png'
import WalletModal from '../WalletModal'
import { ButtonSecondary } from '../Button'
import { utils } from 'ethers'

// import BinanceIcon from '../../assets/images/bnb.svg'
// import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
// import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
// import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'

// import { RowBetween } from '../Row'
import { shortenAddress } from '../../utils'
// import { useAllTransactions } from '../../state/transactions/hooks'
import { NetworkContextName } from '../../constants'
// import { injected, walletconnect, walletlink, fortmatic, portis } from '../../connectors'
import Loader from '../Loader'
/*
const IconWrapper = styled.div<{ size?: number }>`
   ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  } 
`
*/
const Web3StatusGeneric = styled(ButtonSecondary)`
  /* ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  } */
`
const Web3StatusError = styled(Web3StatusGeneric)`
  /* background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  } */
`

const Web3StatusConnect = styled.a<{ faded?: boolean }>`
color: ${({ theme }) => theme.ifoText1};
  /* background-color: ${({ theme }) => theme.ConnectButton}; */
  display: inline-block;
  padding: 13px 13px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 10px;
  margin: 0 2px;

  
  ${Media.sm}{ font-size: 12px; padding: 13px 5px; } 
  
  :hover {
    color: #00f02b;
  }
`
/*const Web3StatusConnect1 = styled(Web3StatusGeneric)<{ faded?: boolean }>`
   background-color: ${({ theme }) => theme.primary4};
  border: none;
  color: ${({ theme }) => theme.primaryText1};
  font-weight: 500;

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.primary4)};
    color: ${({ theme }) => theme.primaryText1};
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.primary5};
      border: 1px solid ${({ theme }) => theme.primary5};
      color: ${({ theme }) => theme.primaryText1};

      :hover,
      :focus {
        border: 1px solid ${({ theme }) => darken(0.05, theme.primary4)};
        color: ${({ theme }) => darken(0.05, theme.primaryText1)};
      }
    `} 
`*/

const Web3StatusConnected = styled.a<{ pending?: boolean }>`
  color: ${({ theme }) => theme.ifoText1};
  
  display: inline-block;
  padding: 13px 13px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 10px;
  margin: 0 2px;
  :hover {
    // background-color: #F3B92E;
  }

  ${Media.sm}{ font-size: 12px; padding: 13px 5px; } 
`
// background-color: ${({ theme }) => theme.ConnectButton};

/*const Web3StatusConnected1 = styled(Web3StatusGeneric)<{ pending?: boolean }>`
   background-color: ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg2)};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg3)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ pending, theme }) => (pending ? darken(0.05, theme.primary1) : lighten(0.05, theme.bg2))};

    :focus {
      border: 1px solid ${({ pending, theme }) => (pending ? darken(0.1, theme.primary1) : darken(0.1, theme.bg3))};
    }
  } 
`*/

const Text = styled.p`
  /* flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500; */
`

const NetworkIcon = styled(Activity)`
  /* margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px; */
`

// we want the latest one to come first, so return negative if a is after b
// function newTranscationsFirst(a: TransactionDetails, b: TransactionDetails) {
//   return b.addedTime - a.addedTime
// }

// function recentTransactionsOnly(a: TransactionDetails) {
//   return new Date().getTime() - a.addedTime < 86_400_000
// }

const SOCK = (
  <span role="img" aria-label="has socks emoji" style={{ marginTop: -4, marginBottom: -4 }}>
    🧦
  </span>
)

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL


export default function Web3Status() {
  const { t } = useTranslation()
  const { active, account, chainId,/* connector,*/ error } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account)

  // const allTransactions = useAllTransactions()

  // const sortedRecentTransactions = useMemo(() => {
  //   const txs = Object.values(allTransactions)
  //   return txs.filter(recentTransactionsOnly).sort(newTranscationsFirst)
  // }, [allTransactions])

  const pending = []//sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = [] //sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  const hasPendingTransactions = !!pending.length
  const hasSocks = useHasSocks()
  const toggleWalletModal = useWalletModalToggle()

  // handle the logo we want to show with the account
  // function getStatusIcon() {
  //   if (connector === injected) {
  //     return <Identicon />
  //   } else if (connector === binanceinjected) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={BinanceIcon} alt={''} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === walletconnect) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={WalletConnectIcon} alt={''} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === walletlink) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={CoinbaseWalletIcon} alt={''} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === fortmatic) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={FortmaticIcon} alt={''} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === portis) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={PortisIcon} alt={''} />
  //       </IconWrapper>
  //     )
  //   }
  // }

  function changeNetwork() {
    const { ethereum } = window
    try {
      ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: utils.hexlify(ACTIVE_NETWORK) }]
      })
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: utils.hexlify(ACTIVE_NETWORK),
                chainName: 'bbc-testnet',
                rpcUrls: [NETWORK_URL] /* ... */,
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }

      }
    }
  }
  function getWeb3Status() {
    if (account && (chainId === ACTIVE_NETWORK)) {
      return (
        <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>

          {hasPendingTransactions ? (
            <>
              {pending?.length} Pending <Loader stroke="white" />
            </>

          ) : (
            <>
              {hasSocks ? SOCK : null}
              {ENSName || shortenAddress(account)}
            </>
          )}
          {/* {!hasPendingTransactions && getStatusIcon()} */}
        </Web3StatusConnected>
      )
    } else if (error) {
      return (
        <Web3StatusConnect onClick={changeNetwork} >
          Change Network
        </Web3StatusConnect>
      )
    } else {
      return (
        <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} faded={!account}>
          {t('connectToWallet')}
        </Web3StatusConnect>
      )
    }
  }

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      {getWeb3Status()}
      <WalletModal ENSName={ENSName} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
