import React from 'react'
import styled from 'styled-components'
import DropDown from '../DropDown'
import TextBox from '../TextBox'
import CheckBox from '../CheckBox'
import Media from '../../theme/media-breackpoint'

import bSCIcon from '../../assets/images/bsc-icon.png';
import ETHIcon from '../../assets/images/sliderICO-02.png';

interface IFOFilterProps {
    symbol: string
    apy: string
    period: string
    viewType: number
    setSymbol: (value) => void
    setApy: (value) => void
    setPeriod: (value) => void
    setViewType: (value) => void
}
export default function IFOFilter({ symbol, apy, period, viewType, setSymbol, setApy, setPeriod, setViewType }: IFOFilterProps) {

    const handleSymbolChange = (value) => {
        setSymbol(value)
    }

    const handleApyChange = (value) => {
        setApy(value)
    }

    const handlePeriodChange = (value) => {
        setPeriod(value)
    }
    return (
        <FilterBox>
            <SearchCBox1>
                <DropDown options={[]} name={"Token Symbol"} ></DropDown>
                <TextBox value={symbol} onUserInput={handleSymbolChange} placeholder='Type the token symbol here'></TextBox>
            </SearchCBox1>
            <SearchCBox1>
                <DropDown options={[]} name={"APY"} ></DropDown>
                <TextBox value={apy} onUserInput={handleApyChange} placeholder='Type the minimum APY here'></TextBox>

                {/* <TextBox placeholder={"Type the minimum APY here"}></TextBox> */}
            </SearchCBox1>
            <SearchCBox1>
                <DropDown options={[]} name={"Type of PDO"} ></DropDown>
                <DropDown options={[{ id: "FIXSALE", name: "FIX SALE" },{ id: "AUCTION", name: "AUCTION" }]} name={"Choose the PDO type"}></DropDown>
                {/* <TextBox value={period} onUserInput={handlePeriodChange} placeholder='Choose the PDO type'></TextBox> */}
            </SearchCBox1>
            <RightBox>

                <CheckBox id={"radio137"} name={"stepin47"} title={"Show only active PDO"} checked={true}></CheckBox>
                <CheckBox id={"chk2"} name={"chk2"} title={"Show only my PDO"} checked={true} ></CheckBox>

                <ViewLinkBox>
                    <ViewLine>
                        <CardButton onClick={() => { setViewType(1) }} viewType={viewType}>
                            <i className="fas fa-square"></i>
                        </CardButton>
                        <ListButton onClick={() => { setViewType(2) }} viewType={viewType}>
                            <i className="fas fa-th-list"></i>
                        </ListButton>
                    </ViewLine>
                    <ViewLine>
                        <CheckBox id={"iconchk1"} name={"iconchk1"} title={""} checked={true}></CheckBox>
                        <img src={bSCIcon} alt="" />
                        <CheckBox id={"iconchk2"} name={"iconchk2"} title={""} checked={true}></CheckBox>
                        <img src={ETHIcon} alt="" />
                    </ViewLine>
                </ViewLinkBox>
            </RightBox>
        </FilterBox>
    )
}

const CardButton = styled.a<{ viewType?: number }>`
    color: ${({ viewType }) => (viewType === 1 ? '#00f02b' : '#8e9195')} ;
    display: inline-block;
    margin: 0 12px 0 0;
    /* color: #000000; */
    font-size: 15px;
`

const ListButton = styled.a<{ viewType?: number }>`
    color: ${({ viewType }) => (viewType === 2 ? '#00f02b' : '#8e9195')} ; 
    display: inline-block;
    margin: 0 12px 0 0;
    /* color: #000000; */
    font-size: 15px;
`

const ViewLine = styled.div`
    display:flex; align-items:center;
    img{height:18px;}
    .md-checkbox{margin:0px !important;}
    
`

const ViewLinkBox = styled.div`
    display: flex;
    align-items:center;
    justify-content:space-between;
`
const FilterBox = styled.div`
    width: 100%;
    display: flex;
    padding: 20px 0;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    /* overflow: hidden; */
    margin: 0;
`
const SearchCBox1 = styled.div`
    width: calc(25% - 32px);
    margin: 0 16px;
    position: relative;
    :after {
        content: "";
        border: 0px;
        width: 1px;
        background-color: ${({ theme }) => theme.border02};
        display: block;
        position: absolute;
        right: -17px;
        top: 0px;
        bottom: 0px;
    }
    @media (max-width: 767px){
        width: calc(100% - 32px);
        margin-bottom: 21px;
        :after { display:none; }
    }
    @media (max-width:500px){
        width:100%; margin:0;
        margin-bottom: 21px;
        :after { display:none; }
    }
    @media (max-width: 991px) and (min-width: 768px){
        width: calc(50% - 32px);
        margin-bottom: 18px;
    }
`

const RightBox = styled.div`
    padding-right: 0;
    // padding-left: 32px;
    width: calc(25% - 36px);
    margin: 0 18px;
    position: relative;

    ${Media.md}{ width: calc(50% - 32px);}
    ${Media.sm}{ width: calc(100% - 32px); padding-left:0;}

`
