import React, {useContext} from 'react'
import styled, { ThemeContext } from 'styled-components'
import ReactTooltip from 'react-tooltip';

const HelpCircles = styled.i`
    font-size: 12px;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    cursor:pointer; 
    &:before{
        content: "\f059"
    }
    &:hover{
        color:#f8f1e3
    }
`
const Circle = styled.i`
    font-size: 13px;
    position: relative;
    top: -11px;
    width: auto!important; 
`
interface ToolTipProps{
    tip:string
    id:string
}
export default function Tooltip({tip, id}){
    const theme = useContext(ThemeContext)
  
    return(
        
        <Circle>
            <HelpCircles data-for={id} data-tip={tip} data-html={true} data-multiline={true}></HelpCircles>
                <ReactTooltip id={id} className={'custTips'}  delayHide={300} multiline={true}
                    textColor={theme.ifoText2} backgroundColor={theme.ifoText1}  effect='solid'
                /> 
        </Circle>
      
    )
}