// import { ChainId } from '@bscswap/sdk'
import React from 'react'
import { useActiveWeb3React } from '../../hooks'
import { useETHBalances } from '../../state/wallet/hooks'

// import { isMobile } from 'react-device-detect'
// import { Text } from 'rebass'
// import { useActiveWeb3React } from '../../hooks'
// import { YellowCard } from '../Card'
// import { useETHBalances } from '../../state/wallet/hooks'
import Web3Status from '../Web3Status'
import Logo from '../../assets/images/pdo-logo.png'
import DarkLogo from '../../assets/images/pdo-logo.png'
import BSCIcon from '../../assets/images/bsc-icon.png'
import styled from 'styled-components'
import Settings from '../Settings'
import { useDarkModeManager } from '../../state/user/hooks'

import dexIcon01 from '../../assets/images/dexIcon-01.png'
import dexIcon02 from '../../assets/images/dexIcon-02.png'
import dexIcon03 from '../../assets/images/dexIcon-03.png'
import dexIcon04 from '../../assets/images/dexIcon-04.png'
import dexIcon05 from '../../assets/images/dexIcon-05.png'
import dexIcon06 from '../../assets/images/dexIcon-06.png'
import dexIcon07 from '../../assets/images/dexIcon-07.png'
import dexIcon08 from '../../assets/images/dexIcon-08.png'
import dexIcon09 from '../../assets/images/dexIcon-09.png'
import dexIcon010 from '../../assets/images/dexIcon-010.png'
import dexIcon011 from '../../assets/images/dexIcon-011.png'
import dexIcon012 from '../../assets/images/dexIcon-012.png'
import dexIcon013 from '../../assets/images/dexIcon-013.png'
import dexIcon014 from '../../assets/images/dexIcon-014.png'
import dexIcon015 from '../../assets/images/dexIcon-015.png'
import dexIcon016 from '../../assets/images/dexIcon-016.png'
import dexIcon017 from '../../assets/images/dexIcon-017.png'
import dexIcon018 from '../../assets/images/dexIcon-018.png'
import dexIcon019 from '../../assets/images/dexIcon-019.png'

import Media from '../../theme/media-breackpoint'
import { useQuery } from '../../hooks/useQuery'



const Nav: React.FC = () => {
  return (
    // <StyledNav>
    //   <StyledUL>
    //     <StyledLI><StyledAbsoluteLink href="/#/list"  className="ani-1" >/PDO</StyledAbsoluteLink></StyledLI>
    //     <StyledLI><StyledAbsoluteLink href="https://bscbonus.com/" target="_blank" className="ani-1">/Welcome bonus</StyledAbsoluteLink></StyledLI>
    //     <StyledLI><StyledAbsoluteLink href="https://trade.bscswap.com/#/swap" target="_blank" className="ani-1">/Swap</StyledAbsoluteLink></StyledLI>


    //   </StyledUL>     
    // </StyledNav>

    <>

      {/* <HeadCenterbox>
        <span>Supporting all DEXs</span>
          <a href='' className="disable"><img src={dexIcon01} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon02} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon03} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon04} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon05} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon06} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon07} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon08} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon09} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon010} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon011} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon012} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon013} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon014} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon015} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon016} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon017} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon018} alt="" /> </a>
          <a href='' className="disable"><img src={dexIcon019} alt="" /> </a>
      </HeadCenterbox> */}
    </>


  )
}

const StyledNav = styled.nav`
  font-family: mont-bold,Arial,Helvetica,sans-serif;
  color: #fffefe;
  position: relative;
  font-size: 13px;
  padding: 0;
  margin: 0;
  float: right;
`

const HeadCenterbox = styled.div`
  width: 100%; max-width: 1120px;  padding: 6px 6px 6px 100px;
  display: flex; align-items:center; justify-content:space-around; 
  span{ position:absolute; left:0px; font-size:14px; font-weight:600; max-width:100px; color:#8e9195;
  } 
  a { display:flex; width:34px; height:34px; background-color:#393d46; border-radius:25px; 
    align-items: center;
    justify-content: center;
    padding: 4px;
    :hover{ background-color:#434b5d;}
    img { max-width:100%; height:auto}

    &.disable{ filter:grayscale(1); cursor:normal; pointer-events:none;}

    ${Media.lg}{
      &:nth-last-child(01), &:nth-last-child(02), &:nth-last-child(03), &:nth-last-child(04) { display:none;}
    }
  } 

  ${Media.xl}{
    max-width:960px;
  }

  ${Media.lg}{
    max-width:700px;
  }
  ${Media.md}{  display: none; }

`;

const StyledUL = styled.ul`
  text-align: left;
  margin: 0 auto 0 auto;
  width: 100%;
  display: block;
  padding: 0;
  font-family: 'Press Start 2P', arial;
  font-size: 12px;
`

const StyledLI = styled.li`
    position: relative;
    text-align: center;
    display: block;
    white-space: nowrap;
    float: left;
`

const StyledAbsoluteLink = styled.a`
    color: ${({ theme }) => theme.ifoText1} !important;
    display: block;
    color: #fffefe;
    padding: 17px 9px;
    line-height: 22px;
    text-decoration: none;
    /* text-transform: uppercase; */
    position: relative;
    text-align: center;
    display: block;
    white-space: nowrap;
    :hover {
      color:#faba37 !important;
    }
`


const HeaderWrapper = styled.header`
  background-color: ${({ theme }) => theme.headerBg} !important;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 19px 20px;
  z-index: 10;

  ${Media.xs}{ padding:8px 12px; }



`
const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

const LogoLink = styled.a`
  margin-right: auto;
  position: relative;
 
`

const LogoImg = styled.img`
  width: 100%;
  max-width:180px;
  height: auto;

  ${Media.xs}{  max-width:120px;  }
`

const HeaderBar01 = styled.div`
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); width: 100%; max-width:1040px;

  ${Media.lg}{
    max-width:700px;
  }
`

const HeaderBar02 = styled.div`
  margin-left: auto;
  position: relative;
  display: block;
`

const BSCImageContainor = styled.a` 
    display: inline-block;
    color: #fff;
    padding: 13px 3px; 
    filter:grayscale(2); 
    opacity:0.4;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
    margin: 0 2px;
    ${Media.xs}{ display:none;}
 
`

const BSCWallBalance = styled.div`
display: inline-block;
font-size: 14px;
font-weight:bold;
padding: 0 4px 0 0;
color: ${({ theme }) => theme.ConnectButton}; 

${Media.xs}{ display:none;}

}
`
const BSCImage = styled.img`
  position: relative;
  top: 4px;
`

const SettingsImage = styled.a`
    color: ${({ theme }) => theme.ifoText1} !important;
    background-color: ${({ theme }) => theme.headerImageBg};
    display: inline-block;
    /* color: #fff; */
    padding: 15px 15px;
    /* background-color: #383b42; */
    border-radius: 10px;
    margin: 0 2px;
    :hover {
      background-color: #f3ba2f;
    }
`

const HeadDotBTN = styled.a`
    
    background-color:#febb00;
    display: inline-block;
    width:9px; height:9px; 
    border-radius: 10px;
    margin:0 5px 0 12px;
    :hover { opacity:0.8;}
    ${Media.xs}{ margin-left:4px; }
`


// const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
//     [ChainId.MAINNET]: 'Wrong Network',
//     [ChainId.RINKEBY]: 'Wrong Network',
//     [ChainId.ROPSTEN]: 'Wrong Network',
//     [ChainId.GÖRLI]: 'Wrong Network',
//     [ChainId.KOVAN]: 'Wrong Network',
//     [ChainId.BSC_MAINNET]: null,
//     [ChainId.BSC_TESTNET]: 'Testnet'
//   }
export default function Header() {
  // const { account, chainId } = useActiveWeb3React()
  const query = useQuery();
  const [isDark] = useDarkModeManager()
  const { account/*, chainId*/ } = useActiveWeb3React()
  const bnbbalance = useETHBalances([account])[account]

  // const userEthBalance = useETHBalances([account])[account]
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <LogoLink href={`/#/list${query.get("preview") !== null ? '?preview=true' : ''}`} >
          <LogoImg src={isDark ? DarkLogo : Logo} id="logoImgSd"></LogoImg>
        </LogoLink>
        <HeaderBar01>
          <Nav />
        </HeaderBar01>
        <HeaderBar02>
          <BSCWallBalance>
            {bnbbalance?.toSignificant(4)}
          </BSCWallBalance>
          <BSCImageContainor>
            <BSCImage src={BSCIcon} ></BSCImage>
          </BSCImageContainor>

          <Web3Status />
          {/* <SettingsImage>
                <i className="fas fa-cog"></i>
              </SettingsImage> */}

          {/* <SettingsImage>
              <i className="fas fa-cog"></i>
              </SettingsImage> */}
          <Settings />

          {/* <HeadDotBTN /> */}

        </HeaderBar02>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

