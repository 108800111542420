import React from 'react'
import styled/*, { ThemeContext }*/ from 'styled-components'
import IFOCard from '../IFOCard/'

interface ActiveIFOProps {
    data
    balance?: string
    refresh?
    price?: number
    viewType: number
}

export default function ActiveIFO({ data, balance, refresh, price, viewType }: ActiveIFOProps) {
    const todayDate = new Date().getTime() / 1000;
    return (
        <>
            {viewType === 1 ? (
                <IFOView>
                    {data.map(d =>
                        (d.percentAgeRaised < 100 && todayDate >= d.startTime && d.endTime >= todayDate && d.totalSupply >= 0.00001)
                        &&
                        <IFOCard
                            key={d.address}
                            data={d}
                            balance={balance}
                            refresh={refresh}
                            price={price}
                            viewType={1}
                        />
                    )}
                </IFOView>
            ) : (
                <ListViewBox>
                    <MainTable width="100%" cellSpacing={0} cellPadding={16}>
                        <TableBody>
                            <HeaderTR>
                                <HeaderTH scope="col">PDO name</HeaderTH>
                                <HeaderTH scope="col">PDO Type</HeaderTH>
                                <HeaderTH scope="col">PDO Progress</HeaderTH>
                                <HeaderTH scope="col">Current PDO ends in</HeaderTH>
                                <HeaderTH scope="col">&nbsp;</HeaderTH>
                                <HeaderTH scope="col">&nbsp;</HeaderTH>
                            </HeaderTR>

                            {data.map(d =>
                                (d.percentAgeRaised < 100 && todayDate >= d.startTime && d.endTime >= todayDate)
                                &&
                                <IFOCard
                                    key={d.address}
                                    data={d}
                                    balance={balance}
                                    refresh={refresh}
                                    price={price}
                                    viewType={2}
                                />
                            )}

                        </TableBody>
                    </MainTable>
                </ListViewBox>
            )}
        </>
    )
}

const IFOView = styled.div`
    display: flex;
    /* overflow: hidden; */
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -16px;
    justify-content: flex-start;
    @media (max-width: 767px){
        margin: 0;
    }
    @media (max-width: 991px) and (min-width: 768px){
        margin: 0 -11px;
    }
`


/////////////////////ListViewBox//////////////////////////////

const ListViewBox = styled.div`
    display: block;
    margin: 35px 0 15px 0;
`

const MainTable = styled.table`
    border:0;
    border-collapse: collapse;
    border-spacing: 0;
    background-color:${({ theme }) => theme.bg6};
`

const HeaderTR = styled.tr`
    border-bottom: 1px solid #393d46;
`

const HeaderTH = styled.th`
    border-top: 2px solid #393d46;
    border-bottom: 2px solid #393d46;
    vertical-align:middle;
    text-align:left;
    color:${({ theme }) => theme.ifoText5} !important;
`
const TableBody = styled.tbody`
`


////////////////////////////////////////////////////////////////////