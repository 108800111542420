import React from 'react'
import styled from 'styled-components'
import Countdown from 'react-countdown'


const SpanTime = styled.span
    <{ viewType?: number }>`
    font-size: ${({ viewType }) => (viewType === 1 ? '18px !important' : '14px !important')};
    border: 1px solid #5a5e67;
    display: inline-block;
    border-radius: 4px; 
    padding: ${({ viewType }) => (viewType === 1 ? '5px' : '4px')} ${({ viewType }) => (viewType === 1 ? '5px' : '5px')};
    margin: 0 2px;   
    // background-color:#2c2f36;
`
interface IFOCounterProp {
    counterTime: number,
    viewType?: number
}

export default function IFOCounter({ counterTime, viewType }: IFOCounterProp) {
    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, milliseconds, completed }) => {
        if (completed) {
            // Render a completed state
            return < > PDO Ended </>
        } else {
            const d = days < 10 ? "0" + days.toString() : days.toString()
            const h = hours < 10 ? "0" + hours.toString() : hours.toString()
            const m = minutes < 10 ? "0" + minutes.toString() : minutes.toString()
            const s = seconds < 10 ? "0" + seconds.toString() : seconds.toString()
            const ms = milliseconds < 10 ? "0" + milliseconds.toString() : milliseconds.toString()

            return (
                <div>
                    <SpanTime viewType={viewType}>{d[0]}</SpanTime>
                    <SpanTime viewType={viewType}>{d[1]}</SpanTime>:
                    <SpanTime viewType={viewType}>{h[0]}</SpanTime>
                    <SpanTime viewType={viewType}>{h[1]}</SpanTime>:
                    <SpanTime viewType={viewType}>{m[0]}</SpanTime>
                    <SpanTime viewType={viewType}>{m[1]}</SpanTime>:
                    <SpanTime viewType={viewType}>{s[0]}</SpanTime>
                    <SpanTime viewType={viewType}>{s[1]}</SpanTime>:
                    <SpanTime viewType={viewType}>{ms[0]}</SpanTime>
                    <SpanTime viewType={viewType}>{ms[1]}</SpanTime>
                </div>

            )
        }
    };
    return (

        <Countdown
            date={new Date(counterTime * 1000)}
            // date = {Date.now() + 100000}
            renderer={renderer}
            intervalDelay={0}
            precision={3}
        />

    )
}

