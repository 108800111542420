import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse } from 'react-collapse'
import IFOCard from '../IFOCard/'
import Media from '../../theme/media-breackpoint'
import Tooltip from '../ToolTip';

const MoreViewBar = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 60px 0 30px 0;
`

const IFOBTN02 = styled.a`
    font-size: 18px;
    font-weight: 700;
    margin: 0 auto;   
    /* margin-left: auto; */
    color: ${({ theme }) => theme.ifoText1};
    /* font-size: 14px;
    font-weight: 700; */
`
const IFOView = styled.div`
    display: flex;
    /* overflow: hidden; */
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -16px; 
    justify-content: flex-start;
   
    ${Media.lg}{ width: 100%; margin: 0 auto;    }




`
const SpanTxn01 = styled.span`
    display: block;
`

interface InactiveIFOProps {
    data,
    balance?: string
    refresh?
    viewType?: number
    price?: number
}
export default function InactiveIFO({
    data,
    balance,
    refresh,
    viewType,
    price
}: InactiveIFOProps) {
    const [isOpened, setIsOpened] = useState(false)
    // const [details, setDetails] = useState("Details")
    const [detailsClass, setDetailsClass] = useState("fas fa-chevron-down")
    const todayDate = new Date().getTime() / 1000;
    return (
        <div>
            <MoreViewBar>
                <IFOBTN02 onClick={() => {
                    setIsOpened(!isOpened)
                    if (isOpened === true) {
                        setDetailsClass("fas fa-chevron-down")

                    } else {
                        setDetailsClass("fas fa-chevron-up")
                    }
                }}>
                    <SpanTxn01>Inactive PDO <i className={detailsClass}></i></SpanTxn01>

                </IFOBTN02>

            </MoreViewBar>
            {isOpened &&
                <Collapse isOpened={isOpened} >
                    {viewType === 1 ? (

                        <IFOView>
                            {

                                data.map(d =>
                                    (d.percentAgeRaised >= 100 || todayDate > d.endTime || d.totalSupply < 0.00001) ?
                                        <IFOCard
                                            key={d.address}
                                            data={d}
                                            balance={balance}
                                            viewType={1}
                                            refresh={refresh}
                                            price={price}
                                        /> : null
                                )}
                        </IFOView>
                    ) : (
                        <ListViewBox>
                            <MainTable width="100%" cellSpacing={0} cellPadding={16}>
                                <TableBody>
                                    <HeaderTR>
                                        <HeaderTH scope="col">PDO name</HeaderTH>
                                        <HeaderTH scope="col">PDO Type</HeaderTH>
                                        <HeaderTH scope="col">PDO Progress</HeaderTH>
                                        <HeaderTH scope="col">PDO Status</HeaderTH>
                                        <HeaderTH scope="col">&nbsp;</HeaderTH>
                                        <HeaderTH scope="col">&nbsp;</HeaderTH>
                                    </HeaderTR>
                                    {

                                        data.map(d =>
                                            (d.percentAgeRaised >= 100 || todayDate > d.endTime) ?
                                                <IFOCard
                                                    key={d.address}
                                                    data={d}
                                                    balance={balance}
                                                    viewType={2}
                                                    refresh={refresh}
                                                    price={price}
                                                /> : null
                                        )}

                                </TableBody>
                            </MainTable>
                        </ListViewBox>
                    )}
                </Collapse>
            }
        </div>
    )
}


/////////////////////ListViewBox//////////////////////////////

const ListViewBox = styled.div`
    display: block;
    margin: 35px 0 15px 0;
`

const BOuter = styled.div`
    text-align:center; padding: 60px 0 30px 0;
`

const CBAButton = styled.button`
    font-size:12px; font-weight:normal; color:${({ theme }) => theme.ifoText5}; margin-bottom:15px; padding:11px 19px; border-radius:5px; background-color:${({ theme }) => theme.ifoBg1}; border:1px solid ${({ theme }) => theme.border01};
    :hover{background-color:${({ theme }) => theme.ifoText5}; color:${({ theme }) => theme.text1};}
`

const GainText = styled.div`
    font-size:11px; font-weight:normal; color:${({ theme }) => theme.ifoText5};
    i{top:-6px; font-size:10px;}
`

const MainTable = styled.table`
    border:0;
    border-collapse: collapse;
    border-spacing: 0;
    background-color:${({ theme }) => theme.bg6};
`

const HeaderTR = styled.tr`
    border-bottom: 1px solid #393d46;
`

const HeaderTH = styled.th`
    border-top: 2px solid #393d46;
    border-bottom: 2px solid #393d46;
    vertical-align:middle;
    text-align:left;
    color:${({ theme }) => theme.ifoText5} !important;
`
const TableBody = styled.tbody`
`


////////////////////////////////////////////////////////////////////