import { AbstractConnector } from '@web3-react/abstract-connector'
import { ChainId, JSBI, Percent, Token, WETH } from '@bscswap/sdk'
import { injected, walletconnect,/* walletlink*/ } from '../connectors'
import pancakeLogo from '../assets/images/pancake-logo.png'
import uniswapLogo from '../assets/images/uniswap-logo.png'
import sushiswapLogo from '../assets/images/sushiswap-logo.png'

export const ROUTER_ADDRESS = '0xd954551853F55deb4Ae31407c423e67B1621424A'
export const BSWAP_LP_ADDRESS = '0xb41A8426e96d0427dd45a2B6EB21996101808616'

//Testnet
// export const WBNB = '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd'
// export const ACTIVE_NETWORK = 97
// export const API_BASEURL = 'http://localhost:8082'
// const NETWORK_URL = 'https://rpc.ankr.com/bsc'
//export const API_BASEURL = 'https://testnet-api.pdo.finance'

//Mainnet
export const WBNB = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
export const ACTIVE_NETWORK = 56
export const API_BASEURL = 'https://api.pdo.finance'    
const NETWORK_URL = 'https://rpc.ankr.com/bsc'

// export const ROUTER_ADDRESS = '0xd954551853F55deb4Ae31407c423e67B1621424A'
// export const BSWAP_LP_ADDRESS = '0xb41A8426e96d0427dd45a2B6EB21996101808616'

export interface SwapInfo {
  name: string
  logo: any
}

export const SUPPORTED_SWAP: { [key: string]: SwapInfo } = {
  PANCAKESWAP: {
    name: 'Pancakeswap',
    logo: pancakeLogo
  },
  UNISWAP: {
    name: 'Uniswap',
    logo: uniswapLogo
  },
  SUSHISWAP: {
    name: 'Sushiswap',
    logo: sushiswapLogo
  }
}
export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,

  },
  // BINANCE: {
  //   connector: binanceinjected,
  //   name: 'Binance Chain Wallet',
  //   iconName: 'bnb.svg',
  //   description: 'A Crypto Wallet for Binance Smart Chain',
  //   href: null,
  //   color: '#F9A825'
  // },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLETCONNECT: {
    connector: walletconnect,
    name: 'Wallet Connect',
    iconName: 'walletConnectIcon.svg',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    mobile: true
  },
  // TRUSTWALLET: {
  //   connector: injected,
  //   name: 'TrustWallet',
  //   iconName: 'trustwallet.svg',
  //   description: 'The most trusted & secure crypto wallet',
  //   href: null,
  //   color: '#3375BB'
  // },
  // MATHWALLET: {
  //   connector: injected,
  //   name: 'MathWallet',
  //   iconName: 'mathwallet.svg',
  //   description: 'Your Gateway to the World of Blockchain',
  //   href: null,
  //   color: '#000000'
  // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'https://tokens.bscswap.com/tokens.json'


export const WEI_CONVERT = {
  "0": "noether",
  "1": "wei",
  "3": "kwei",
  "6": "mwei",
  "9": "gwei",
  "12": "szabo",
  "15": "finney",
  "18": "ether",
  "21": "kether",
  "24": "mether",
  "27": "gether",
  "30": "tether"
}