import React, { useRef, useEffect, useContext/*, useState*/ } from 'react'
// import { Settings, X } from 'react-feather'
import styled from 'styled-components'

import {

  useDarkModeManager
} from '../../state/user/hooks'
// import SlippageTabs from '../SlippageTabs'
import { RowFixed, RowBetween } from '../Row'
import { TYPE } from '../../theme'
// import QuestionHelper from '../QuestionHelper'
import Toggle from '../Toggle'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../Column'
// import { ButtonError } from '../Button'
import { useSettingsMenuOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import { Text } from 'rebass'
// import Modal from '../Modal'
import { useTranslation } from 'react-i18next'


const StyledMenuButton = styled.a`
    color: ${({ theme }) => theme.ifoText1};
    background-color: ${({ theme }) => theme.headerImageBg};
    display: inline-block;
    /* color: #fff; */
    padding: 15px 15px;
    /* background-color: #383b42; */
    border-radius: 10px;
    margin: 0 2px;
    :hover {
      background-color: #f3ba2f;
    }
`


const StyledMenu = styled.i`
  
`

const HeadDotBTN = styled.a`
    background-color:#00f02b;
    display: inline-block;
    width:6px; height:6px; 
    border-radius: 10px;
    margin:0 5px 0 12px;
    box-shadow: 0 0 0 rgba(0, 240, 43, 0.4);
    animation: pulse 2s infinite; 
    position:relative;
    :hover {
      opacity:0.8;
    }
    :before{ content:''; width:8px; height:8px; border-radius:10px; position:absolute; left:-1px; top:-12px; background-color:#00f02b; animation: pulse 2s infinite;}
    :after{ content:''; width:4px; height:4px; border-radius:10px; position:absolute; top:auto; left:1px; bottom:-8px; background-color:#00f02b; animation: pulse 2s infinite;}
    @-webkit-keyframes pulse {
      0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0.4);
      }
      70% {
        -webkit-box-shadow: 0 0 0 10px rgba(0, 240, 43, 0);
      }
      100% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0);
      }
    }
    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0.4);
        box-shadow: 0 0 0 0 rgba(0, 240, 43, 0.4);
      }
      70% {
        -moz-box-shadow: 0 0 0 10px rgba(0, 240, 43, 0);
        box-shadow: 0 0 0 10px rgba(0, 240, 43, 0);
      }
      100% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0);
        box-shadow: 0 0 0 0 rgba(0, 240, 43, 0);
      }
    }
`;

const MenuFlyout = styled.span`
  min-width: 20.125rem;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);

  border: 1px solid ${({ theme }) => theme.bg3};

  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 18.125rem;
    right: -46px;
  `};
`


export default function SettingsTab() {
  const node = useRef<HTMLDivElement>()
  const open = useSettingsMenuOpen()
  const toggle = useToggleSettingsMenu()
  const { t } = useTranslation()

  const theme = useContext(ThemeContext)
  // const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

  // const [deadline, setDeadline] = useUserDeadline()

  // const [expertMode, toggleExpertMode] = useExpertModeManager()

  const [darkMode, toggleDarkMode] = useDarkModeManager()

  // show confirmation view before turning on
  // const [showConfirmation, setShowConfirmation] = useState(false)

  useEffect(() => {
    const handleClickOutside = e => {
      if (node.current?.contains(e.target) ?? false) {
        return
      }
      toggle()
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open, toggle])

  return (
    <StyledMenu ref={node}>

      {/* <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
          <i className="fas fa-ellipsis-h"></i>  
        <HeadDotBTN></HeadDotBTN>
        
      </StyledMenuButton> */}

      <HeadDotBTN id="open-settings-dialog-button"></HeadDotBTN>


      {open && (
        <MenuFlyout>
          <AutoColumn gap="md" style={{ padding: '1rem' }}>
            {/* <Text fontWeight={600} fontSize={14}>
              {t('transactionSettings')}
            </Text> */}

            <Text fontWeight={600} fontSize={14} color={theme.ifoText1}>
              {t('interfaceSettings')}
            </Text>

            <RowBetween>
              <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  {t('toggleDarkMode')}
                </TYPE.black>
              </RowFixed>
              <Toggle isActive={darkMode} toggle={toggleDarkMode} />
            </RowBetween>
          </AutoColumn>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
